import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const sizeClasses = {
  "very-small": "text-xs",
  small: "text-sm",
  medium: "text-base",
  large: "text-lg",
};

const iconSize = {
  "very-small": 14,
  small: 18,
  medium: 22,
  large: 26,
};

const contentPosition = {
  left: "justify-content-start",
  center: "justify-content-center",
  right: "justify-content-end",
};

const HiddenText = ({ value, size = "small", position = "right" }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <span
      className={`d-flex align-items-center ${contentPosition[position]} ${sizeClasses[size]}`}
    >
      <span>{isVisible ? value : "XXXXXX"}</span>
      <span
        className="cursor-pointer ml-1"
        onClick={() => setIsVisible(!isVisible)}
      >
        {isVisible ? (
          <VisibilityOff fontSize="inherit" sx={{ fontSize: iconSize[size] }} />
        ) : (
          <Visibility fontSize="inherit" sx={{ fontSize: iconSize[size] }} />
        )}
      </span>
    </span>
  );
};

export default HiddenText;
