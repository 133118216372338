import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  Tabs,
  Tab,
  TextField,
  Card,
  CardHeader,
  Link,
  Tooltip,
  Avatar,
  Skeleton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  requestStatusColor,
  textCapitalized,
} from "../../common/components/Utils";
import AddIcon from "@mui/icons-material/Add";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ViewCaseAttachments from "./ViewCaseAttachments";
import {
  getCaseLetters,
  getFaultWithSanction,
  initiateRespondLetter,
  onViewLetter,
  signLetter,
} from "../../../store/discipline/actions";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import SignLetter from "../../Documents/LetterFormat/SignLetter";
import ViewLetterDialog from "./ViewLetterDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import InstitutionDetails from "../../common/components/InstitutionDetails";
import { showError } from "../../toastify";
import moment from "moment";

const ViewCaseDialog = (props) => {
  const {
    showCaseDetail,
    setShowCaseDetail,
    getFaultWithSanction,
    faults,
    caseRequest,
    getCaseLetters,
    caseLetters,
    signLetter,
    initiateRespondLetter,
    onViewLetter
  } = props;

  const [caseDescription, setCaseDescription] = useState(
    caseRequest?.request?.caseDescription || null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    getCaseLetters(caseRequest?.request?.id || "", setIsLoading);
  }, [caseRequest]);
  useEffect(() => {
    if (!faults.length) getFaultWithSanction();
  }, []);

  // useEffect(() => {
  //   if (!!faults.length) {
  //     let _tmpFaults = [];
  //     faults.forEach((fault) => {
  //       if (!caseRequest?.faults.find(({ faultId }) => fault.id === faultId)) {
  //         _tmpFaults.push(fault);
  //       }
  //     });
  //     setFilteredFaults(_tmpFaults);
  //   }
  // }, [caseRequest, faults]);

  useEffect(() => {
    setCaseDescription(caseRequest?.request?.caseDescription || null);
  }, [caseRequest]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onClose = () => {
    setShowCaseDetail(false);
  };

  return (
    <>
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showCaseDetail}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-9">
              <h5>
                View Case #{caseRequest?.request?.id}
                {caseRequest?.request?.caseStatusId === 1 &&
                  caseRequest?.request?.currentLetterStatusId &&
                  caseRequest?.request?.currentLetterDesc && (
                    <>
                      {" "}
                      -
                      <span
                        className="py-0 px-1 text-secondary"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        {caseRequest.request.currentLetterDesc}
                      </span>{" "}
                      <span className="py-0 px-0" style={{ fontSize: "15px" }}>
                        -
                      </span>
                      <span
                        style={{ fontSize: "15px" }}
                        className={`py-0 px-1 text-${requestStatusColor(
                          [
                            {
                              className: `${
                                caseRequest.request.currentLetterStatusId === 1
                                  ? "warning"
                                  : "success"
                              }`,
                              statusIds: [
                                +caseRequest.request.currentLetterStatusId,
                              ],
                            },
                          ],
                          caseRequest.request.currentLetterStatusId
                        )}`}
                      >
                        {caseRequest.request.currentLetterStatusName}
                      </span>{" "}
                    </>
                  )}
              </h5>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col">{/* xxx */}</div>
                <div className="col">
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 2,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <span className="material-icons">close</span>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <span
                className={`badge mt-3 mx-1 badge-${requestStatusColor(
                  [
                    {
                      className: "primary",
                      statusIds: [1],
                    },
                    {
                      className: "success",
                      statusIds: [2],
                    },
                  ],
                  caseRequest.request.caseStatusId
                )}`}
              >
                {caseRequest.request.caseStatusName}
              </span>
              {/* {caseRequest.request.isSuspended &&   */}
              <span className={`badge mt-3 mx-1 badge-warning`}>
                You have been provisionally suspended
              </span>
              {/* } */}
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="mt-0 mx-4" dividers>
          <Box sx={{ width: "100%", minHeight: "80vh" }} className="mt-1">
            <React.Fragment>
              <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                {caseRequest && caseRequest?.request && (
                  <Card
                    className={`rounded hoverable-card mb-2 mt-2 pb-3`}
                    variant="outlined"
                    sx={{
                      backgroundColor: `#cdeef8`,
                      border: "none",
                    }}
                  >
                    <CardHeader
                      className="pb-0 pt-2"
                      avatar={
                        <Tooltip title={caseRequest.request.firstName}>
                          <Box
                            sx={{
                              position: "relative",
                              display: "inline-flex",
                              cursor: "default",
                            }}
                          >
                            <Avatar
                              sizes="large"
                              style={{
                                border: "1px solid #d1cbcb",
                              }}
                              alt={caseRequest.request.firstName}
                              className=""
                              src={`data:image/jpg;base64,${caseRequest?.profilePicture}`}
                            />
                          </Box>
                        </Tooltip>
                      }
                      title={
                        <span
                          className="d-flex flex-column"
                          style={{ maxWidth: "400px" }}
                        >
                          <span className="d-flex align-items-center text-uppercase font-weight-bold text-primary">
                            {caseRequest.request.firstName}{" "}
                            {caseRequest.request.lastName}
                          </span>
                          <span className="d-block text-primary ml-1 p-1 text-sm">
                            {caseRequest.request.positionName}{" "}
                            <strong className="text-dark">
                              @{caseRequest.request.unitName.toUpperCase()}
                            </strong>
                          </span>
                        </span>
                      }
                      subheader={
                        <span className="d-flex align-items-center flex-wrap justify-content-between ">
                          <span className="d-flex align-items-center flex-wrap">
                            <span className="">
                              <span className="d-flex align-items-center  flex-wrap">
                                <span
                                  className={`d-flex align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                  style={{
                                    cursor: "default",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <span className={`badge badge-primary m-1`}>
                                    {caseRequest.request.totalCases || 0}
                                  </span>
                                  All cases
                                </span>

                                <span
                                  className={`d-flex align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                  style={{
                                    cursor: "default",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <span className={`badge badge-warning m-1`}>
                                    {caseRequest.request.totalOngoing || 0}
                                  </span>
                                  Ongoing
                                </span>

                                <span
                                  className={`d-flex  align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                  style={{
                                    cursor: "default",
                                    // borderRight: "2px solid #ccd0d6",
                                    // borderLeft: "2px solid #ccd0d6",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <span className={`badge badge-secondary m-1`}>
                                    {caseRequest.request.totalClosed || 0}
                                  </span>
                                  Closed Cases
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      }
                    />
                  </Card>
                )}

                <div className="card service-card valid mb-2 mt-3">
                  <div
                    className="card-header"
                    style={{ padding: "0.25rem 1.2rem" }}
                  >
                    Administrative letters
                  </div>
                  <div className="card-body">
                    {caseRequest &&
                      caseRequest?.request &&
                      !!caseRequest?.request?.isEmployeeDelayedToRespond && (
                        <Card
                          className={`rounded hoverable-card mb-2 pb-0`}
                          variant="outlined"
                          sx={{
                            backgroundColor: `hsl(355, 98%, 29%)`,
                            border: "none",
                          }}
                        >
                          <CardHeader
                            className="pb-0 my-0 mx-1"
                            style={{ padding: "0px" }}
                            avatar={
                              <span className={`font-weight-bold text-white`}>
                                <InfoIcon />
                              </span>
                            }
                            title={
                              <>
                                <strong className="text-white  mt-0">
                                  Your response to the requested explanation was
                                  due on{" "}
                                  {moment(
                                    caseRequest?.request
                                      ?.currentLetterSignedOn || null
                                  ).format("lll")}{" "}
                                  and is now overdue.
                                </strong>
                              </>
                            }
                          />
                        </Card>
                      )}

                    {caseRequest &&
                      caseRequest?.request &&
                      !caseRequest?.request?.isEmployeeDelayedToRespond &&
                      ((caseRequest?.request?.currentLetterStatusId === 2 &&
                        caseRequest?.request?.currentLetterTypeId === 1) ||
                        (caseRequest?.request?.currentLetterStatusId === 1 &&
                          caseRequest?.request?.currentLetterTypeId === 5)) && (
                        <Card
                          className={`rounded hoverable-card mb-2 py-1`}
                          variant="outlined"
                          sx={{
                            backgroundColor: `#1976d2`,
                            border: "none",
                          }}
                        >
                          <CardHeader
                            className="pb-0 my-0 mx-1"
                            style={{ padding: "0px" }}
                            avatar={
                              <span className={`font-weight-bold text-white`}>
                                <InfoIcon />
                              </span>
                            }
                            title={
                              <>
                                <strong className="text-white  mt-0">
                                  The deadline to respond to the requested
                                  explanation was{" "}
                                  {moment(
                                    caseRequest?.request
                                      ?.currentLetterSignedOn || null
                                  ).format("lll")}
                                  . Since the response period has expired, you
                                  can no longer submit your explanation
                                </strong>
                              </>
                            }
                          />
                        </Card>
                      )}
                    {!caseLetters.length && isLoading && (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          className="mb-3 mt-2"
                          height={118}
                        />
                        <Skeleton
                          variant="rectangular"
                          className="mb-3"
                          height={96}
                        />
                      </div>
                    )}
                    {!!caseLetters.length && (
                      <>
                        {caseLetters.map((pendingLetter, index) => (
                          <ViewAdministrativeLetterCard
                            key={index}
                            pendingLetter={pendingLetter}
                            caseRequest={caseRequest}
                            initiateRespondLetter={initiateRespondLetter}
                            onViewLetter={onViewLetter}
                            onClose={onClose}
                            signLetter={signLetter}
                            canRespond={
                              [1].includes(
                                caseRequest?.request?.caseStatusId
                              ) &&
                              [1].includes(
                                caseRequest?.request?.currentLetterTypeId
                              ) &&
                              [2].includes(
                                caseRequest?.request?.currentLetterStatusId
                              )
                            }
                          />
                        ))}
                      </>
                    )}
                  </div>
                </div>

                <div className="card service-card valid mb-2 mt-3">
                  <div
                    className="card-header  d-flex justify-content-between align-items-center"
                    style={{ padding: "0.25rem 1.2rem" }}
                  >
                    II. Faults
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <table className="table-striped table-hover table-sm  fixTableHead">
                          <thead>
                            <tr style={{ backgroundColor: "#f0f2f5" }}>
                              <th scope="col" className="text-left">
                                Fault
                              </th>

                              <th scope="col" className="text-left">
                                Category
                              </th>

                              <th scope="col" className="text-left">
                                Sanction
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {caseRequest?.faults.map((fault, index) => (
                              <tr key={index}>
                                <td className="text-left">
                                  <strong> {fault.faultName} </strong>
                                </td>
                                <td>{fault.faultCategoryName} Level</td>
                                <td>{fault.faultSanctionName}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card service-card valid mb-2 mt-3">
                  <div
                    className="card-header"
                    style={{ padding: "0.25rem 1.2rem" }}
                  >
                    III. Description
                  </div>
                  <div className="card-body">
                    <fieldset className="form-group border p-2">
                      <legend
                        className="w-auto px-2"
                        style={{
                          color: "rgba(0, 0, 0, 0.6)",
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontWeight: 400,
                          fontSize: "0.82rem",
                          // lineHeight: "1em",
                          letterSpacing: "0.00938em",
                        }}
                      >
                        Case Description
                      </legend>
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ marginTop: "-5px" }}
                      >
                        {caseDescription}
                      </div>
                    </fieldset>
                  </div>
                </div>

                {caseRequest?.request?.reportCommitteeDate && (
                  <>
                    <div className="card service-card valid mb-2 mt-3">
                      <div
                        className="card-header"
                        style={{ padding: "0.25rem 1.2rem" }}
                      >
                        IV. Report of internal disciplinary committee summary
                      </div>
                      <div className="card-body">
                        <fieldset className="form-group border p-2">
                          <legend
                            className="w-auto px-2"
                            style={{
                              color: "rgba(0, 0, 0, 0.6)",
                              fontFamily:
                                '"Roboto","Helvetica","Arial",sans-serif',
                              fontWeight: 400,
                              fontSize: "0.82rem",
                              // lineHeight: "1em",
                              letterSpacing: "0.00938em",
                            }}
                          >
                            Effected Date
                          </legend>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ marginTop: "-5px" }}
                          >
                            {caseRequest?.request?.reportCommitteeDate
                              ? moment(
                                  caseRequest?.request?.reportCommitteeDate ||
                                    null
                                ).format("ll")
                              : "--------------------"}

                            {/* {reportCommitteeDate &&
                                isEditable &&
                                hasPermissions(["IS_HR"]) && (
                                  <Button variant="outlined" size="small">
                                    <IconButton
                                      disabled={!isEditable}
                                      size="small"
                                      edge="end"
                                      color="primary"
                                      onClick={() => {
                                        setShowCaseReportCommitteeDateForm(
                                          true
                                        );
                                      }}
                                    >
                                      <span className="material-icons">
                                        edit
                                      </span>
                                    </IconButton>
                                  </Button>
                                )} */}
                          </div>
                        </fieldset>
                        <fieldset className="form-group border p-2">
                          <legend
                            className="w-auto px-2"
                            style={{
                              color: "rgba(0, 0, 0, 0.6)",
                              fontFamily:
                                '"Roboto","Helvetica","Arial",sans-serif',
                              fontWeight: 400,
                              fontSize: "0.82rem",
                              // lineHeight: "1em",
                              letterSpacing: "0.00938em",
                            }}
                          >
                            Summary of the conclusions of the committee
                          </legend>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ marginTop: "-5px" }}
                          >
                            {caseRequest?.request?.reportCommitteeSummary ||
                              "--------------------"}
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </>
                )}
              </Typography>
            </React.Fragment>
          </Box>

          <ViewCaseAttachments
            caseId={caseRequest?.request?.id}
            canEdit={false}
          />
        </DialogContent>
        <DialogActions className="py-3 mx-4 d-flex justify-content-center"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  caseRequest,
  faults,
  caseLetters,
}) => {
  return {
    user,
    loading,
    caseRequest,
    faults,
    caseLetters,
  };
};
export default connect(mapStateToProps, {
  getFaultWithSanction,
  getCaseLetters,
  signLetter,
  initiateRespondLetter,
  onViewLetter
})(ViewCaseDialog);

const ViewAdministrativeLetterCard = (props) => {
  const {
    pendingLetter,
    caseRequest,
    canRespond,
    initiateRespondLetter,
    onViewLetter,
    onClose,
    signLetter,
  } = props;

  const [showLetter, setShowLetter] = useState(false);
  const [onViewDocument, setOnViewDocument] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState(null);

  const [enableToFillLetter, setEnableToFillLetter] = useState(false);
  const [showCaseApproverForm, setShowCaseApproverForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [pkiPassword, setPkiPassword] = useState("");

  const [formData, setFormData] = useState({
    id: pendingLetter?.id,
    caseId: pendingLetter?.caseId,
    letterBodyContent: pendingLetter?.letterBodyContent,
    ccInstitutionNames: pendingLetter?.ccInstitutionNames,
  });
const viewLetter=()=>{
  if (
    !pendingLetter?.letterViewedOn
  ) {
    onViewLetter(
      pendingLetter?.id,
      (isLoading, data) => {
        setIsLoading1(isLoading);
        if (data) {
          pendingLetter.letterViewedOn = data;
          setSelectedLetter(pendingLetter);
          setOnViewDocument(true);
        }
      }
    );
  } else {
    setSelectedLetter(pendingLetter);
    setOnViewDocument(true);
  }
}
  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <Card
          className={`mb-2 w-100 rounded hoverable-card card border-${
            pendingLetter?.letterStatusId === 1
              ? "primary"
              : pendingLetter?.letterStatusId === 2
              ? "success"
              : pendingLetter?.letterStatusId === 4
              ? "warning"
              : "danger"
          }`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <>
                {pendingLetter?.docReferenceId ? (
                  <>
                    <span className={`font-weight-bold text-success`}>
                      <FilePresentIcon
                        // fontSize="large"
                        style={{
                          color: !!pendingLetter?.docReferenceId
                            ? "#fe4066"
                            : "#c57c7cb8",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={viewLetter}
                      />
                    </span>
                  </>
                ) : (
                  <>
                    <span className={`font-weight-bold text-primary`}>
                      <TextSnippetIcon />
                    </span>
                  </>
                )}
              </>
            }
            title={
              <Typography>
                <strong>
                  {!!pendingLetter && (
                    <>
                      {!!pendingLetter?.docReferenceId ? (
                        <>
                          <span
                            className="text-primary"
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={viewLetter}
                          >
                            {pendingLetter?.letterDesc || ""}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="text-primary">
                            {pendingLetter?.letterDesc || ""}
                          </span>
                        </>
                      )}
                      <span
                        class={`badge ml-1 bg-${
                          pendingLetter?.letterStatusId === 1
                            ? "primary"
                            : pendingLetter?.letterStatusId === 2
                            ? "success"
                            : pendingLetter?.letterStatusId === 4
                            ? "warning"
                            : "danger"
                        } text-white px-2`}
                      >
                        {pendingLetter?.letterStatusName}
                      </span>{" "}
                      <br />
                      <small className="badge badge-pill text-dark px-0">
                        {" "}
                        #{pendingLetter?.letterNo || ""}
                      </small>
                    </>
                  )}
                </strong>
              </Typography>
            }
            action={
              <>
                <div
                  className="d-flex flex-row align-items-center pull-right"
                  style={{ borderRadius: "4px" }}
                >
                  {pendingLetter?.docReferenceId && (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        className="mx-1"
                        size="small"
                        disabled={isLoading1}
                        onClick={viewLetter}
                      >
                        {" "}
                        {isLoading1 ? "Wait...." : "View Letter"}{" "}
                      </Button>
                    </>
                  )}

                  {canRespond && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        className="mx-1"
                        size="small"
                        startIcon={<AddIcon />}
                        disabled={isLoading}
                        onClick={() => {
                          if (!!pendingLetter?.isEmployeeDelayedToRespond) {
                            showError(
                              `Your response to the requested explanation was due on ${moment(
                                caseRequest?.request?.currentLetterSignedOn ||
                                  null
                              ).format("lll")} and is now overdue.`
                            );
                          } else if (!pendingLetter?.letterViewedOn) {
                            showError(
                              `You should first view the letter before clicking to respond button.`
                            );
                          } else {
                            initiateRespondLetter(
                              { caseId: caseRequest?.request?.id },
                              caseRequest,
                              (isLoading, data) => {
                                setIsLoading(isLoading);
                                if (data) {
                                  setSelectedLetter(data);
                                  setShowLetter(true);
                                  setEnableToFillLetter(true);
                                }
                              }
                            );
                          }
                        }}
                      >
                        {isLoading ? "Wait...." : "Respond"}
                      </Button>
                    </>
                  )}

                  {pendingLetter?.letterStatusId === 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="mx-1"
                      size="small"
                      startIcon={
                        <span className="material-icons-outlined">
                          next_plan
                        </span>
                      }
                      onClick={() => {
                        setShowLetter(true);
                        setSelectedLetter(pendingLetter);
                        setEnableToFillLetter(true);
                      }}
                    >
                      {" "}
                      Resume
                    </Button>
                  )}
                </div>
              </>
            }
          />
        </Card>
        {onViewDocument && selectedLetter && (
          <PreviewPdfUrl
            showDocument={onViewDocument}
            setShowDocument={setOnViewDocument}
            document={{
              name: `${selectedLetter?.letterDesc || ""}`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                selectedLetter?.docReferenceId,
            }}
          />
        )}

        {showLetter && caseRequest && (
          <ViewLetterDialog
            selectedLetter={selectedLetter}
            showLetter={showLetter}
            setShowLetter={setShowLetter}
            setEnableToFillLetter={setEnableToFillLetter}
            employee={{
              lastName: caseRequest?.request?.lastName,
              firstName: caseRequest?.request?.firstName,
              phoneNumber: caseRequest?.request?.phoneNumber,
              email: caseRequest?.request?.email,
              entityName: caseRequest?.request?.entityName,
              entitySectorId: caseRequest?.request?.entitySectorId,
              address: caseRequest?.request?.address,
            }}
            signContent={
              <>
                <InstitutionDetails
                  headOfInstitution={{
                    isDelegated: false,
                    isMinster: false,
                    firstName: caseRequest?.request?.lastName,
                    lastName: caseRequest?.request?.firstName,
                    institutionName: caseRequest?.request?.entityName,
                    positionName: caseRequest?.request?.positionName,
                    canAddInstitutionName: false,
                  }}
                  userSignature={null}
                />
              </>
            }
            onUpdateLetter={(formData) => {
              setFormData({ ...formData });
            }}
            action={
              <>
                {!enableToFillLetter && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className="float-right mx-1 my-1"
                      startIcon={
                        <span className="material-icons-outlined">edit</span>
                      }
                      onClick={() => {
                        setEnableToFillLetter(true);
                      }}
                    >
                      Edit
                    </Button>

                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      className="float-right mx-1 my-1"
                      startIcon={
                        <span className="material-icons-outlined">
                          task_alt
                        </span>
                      }
                      onClick={() => {
                        setShowCaseApproverForm(true);
                      }}
                    >
                      {" "}
                      Sign
                    </Button>
                  </>
                )}
              </>
            }
            enableToFillLetter={enableToFillLetter}
          />
        )}

        {showCaseApproverForm && (
          <ConfirmationDialog
            disabled={!pkiPassword}
            confirmationDialog={showCaseApproverForm}
            message={
              <>
                <p className="px-4">
                  <SignLetter />
                </p>
                <p>Enter your RISA Digital signature password to sign</p>
                <form>
                  <TextField
                    fullWidth
                    size="small"
                    id="pki-password"
                    rows={4}
                    name="pki-password"
                    type="password"
                    label="Enter your RISA Digital signature password to sign"
                    variant="outlined"
                    placeholder="Enter your RISA Digital signature password to sign"
                    value={pkiPassword}
                    onChange={(e) => {
                      setPkiPassword(e.target.value || "");
                    }}
                  />
                </form>
              </>
            }
            setConfirmationDialog={setShowCaseApproverForm}
            onYes={() => {
              formData.password = pkiPassword;
              formData.caseStatusId = 5;
              formData.caseLogStatusId = 7;
              formData.caseId = caseRequest?.request?.id;
              formData.letterId = pendingLetter.id;
              signLetter(formData, onClose);
            }}
          />
        )}
      </div>
    </>
  );
};
