import "suneditor/dist/css/suneditor.min.css";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";
import { useEffect } from "react";
import ErrorMessage from "./ErrorMessage";
import axios from "axios";
import { useState } from "react";
import { showError } from "../../toastify";
import { Alert } from "@mui/material";
import ActionDialog from "./ActionDialog";
// https://github.com/JiHong88/SunEditor#install

const TextEditorComponent = (props) => {
  const { id, onEditorChange, options } = props;
  const textareaId = id || "editor-textarea";
  const {
    autoSave = false,
    api = "",
    localStorageKey = "",
    defaultValue,
    maxCharCount,
    hasChanged,
    height = "300px",
  } = options;
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(!!hasChanged || false);
  const [isMaxChar, setIsMaxChar] = useState(false);

  const autoSaveContent = async (api, body) => {
    try {
      setIsSaving(true);
      await axios.put(api, body);
      setIsChanged(false);
      setIsSaving(false);
    } catch (error) {
      setIsChanged(false);
      setIsSaving(false);
      showError(error);
    }

    onEditorChange({
      isSaving: false,
      isMaxChar: isMaxChar,
      isChanged: false,
      contents: body.contents,
    });
  };

  useEffect(() => {
    // Check if the textarea element with the specified ID exists
    const textarea = document.getElementById(textareaId);

    if (textarea) {
      const editor = suneditor.create(textareaId, {
        value: defaultValue,
        defaultTag: "div",
        charCounter: !!maxCharCount ? true : false,
        maxCharCount: maxCharCount,
        // alignItems:"justify",

        plugins: plugins,
        font: [
          "Times New Roman",
          "Arial",
          "Verdana",
          "Helvetica",
          "sans-serif",
          "Ronnia"
        ],
        buttonList: [
          ["undo", "redo"],
          ["font", "fontSize", "formatBlock"],
          ["paragraphStyle", "blockquote"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          ["fontColor", "hiliteColor"],
          ["removeFormat"],
          [
            "outdent",
            "indent",
            "align",
            "horizontalRule",
            "list",
            "lineHeight",
          ],
          ["align", "horizontalRule", "list", "lineHeight"],
          ["table", "link", "image", "video", "audio" /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
          /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
          ["fullScreen", "showBlocks", "codeView"],
          ["preview", "print"],
          ["save", "template"],
          ["dir", "dir_ltr", "dir_rtl"],
          // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
        ],
        width: "100%",
        minHeight: height,  // Minimum height to start
        autoHeight: true,     // Auto-expand based on content
        //   onChange: (result) => {
        //     onEditorChange(result);
        //   },
      });
      //editor.setContent(content);
      // You can handle events and other functionality here
      editor.setDefaultStyle(
        `font-family: ${
          options?.styles?.fontFamily || "Times New Roman!important"
        };
         font-size: ${(options?.styles?.fontSize || "16") + "px!important"};
        align-items:${options?.styles?.textAlign || "justify"};
        line-height:${options?.styles?.lineHeight || "1.5"}`
      );
      //   editor.onScroll = function (e, core) {
      //     console.log("onScroll", e);
      //   };

      //   editor.onMouseDown = function (e, core) {
      //     console.log("onMouseDown", e);
      //   };

      //   editor.onClick = function (e, core) {
      //     console.log("onClick", e);
      //   };

      //   editor.onKeyDown = function (e, core) {
      //     console.log("onKeyDown", e);
      //   };

      //   editor.onKeyUp = function (e, core) {
      //     console.log("onKeyUp", e);
      //   };

      //   editor.onFocus = function (e, core) {
      //     console.log("onFocus", e);
      //   };

      //   editor.onInput = function (e, core) {
      //   console.log("onInput", e);
      // };

      editor.onBlur = (contents, core) => {
        const isSaving = false,
          isChanged = true;
        let isMaxChar = false;
        const chars = editor.getCharCount("char");
        if (maxCharCount && maxCharCount <= chars) isMaxChar = true;

        onEditorChange({
          isSaving: isSaving,
          isMaxChar: isMaxChar,
          isChanged: isChanged,
          contents: contents.target.innerHTML,
        });

        if (localStorageKey)
          window.localStorage.setItem(
            localStorageKey,
            contents.target.innerHTML
          );
        setIsMaxChar(isMaxChar);
        setIsChanged(isChanged);
        setIsSaving(isSaving);
      };

      // onchange event
      // contents: core.getContents(), Core object
      editor.onChange = (contents, core) => {
        const isSaving = false,
          isChanged = true;
        let isMaxChar = false;
        const chars = editor.getCharCount("char");
        if (maxCharCount && maxCharCount <= chars) isMaxChar = true;

        onEditorChange({
          isSaving: isSaving,
          isMaxChar: isMaxChar,
          isChanged: isChanged,
          contents: contents,
        });

        if (localStorageKey)
          window.localStorage.setItem(localStorageKey, contents);
        setIsMaxChar(isMaxChar);
        setIsChanged(isChanged);
        setIsSaving(isSaving);
      };
      //Cleanup when the component is unmounted
      return () => {
        editor.destroy();
      };
    }
  }, []);

  return (
    <>
      <ErrorMessage
        hasError={!!autoSave && !api}
        message="API is not provided"
      />

      {!!autoSave && isSaving && (
        <ActionDialog showAction={isSaving} action="Saving...." />
      )}

      <textarea id={`${textareaId}`}></textarea>
      <ErrorMessage
        hasError={isMaxChar}
        message={`Maximum characters should be less than ${maxCharCount}`}
      />
    </>
  );
};

export default TextEditorComponent;
