import { defaultState } from "./state";
import commonTypes from "./action-types";
import rbmTypes from "../rbm/action-types";
const types = { ...commonTypes, ...rbmTypes };

const env = (envState = defaultState.env, action) => {
  switch (action.type) {
    case types.SET_ENV:
      return action.data;

    default:
      return envState;
  }
};

const loading = (loadingState = defaultState.loading, action) => {
  switch (action.type) {
    case types.START_LOADING:
      return true;

    case types.END_LOADING:
      return false;

    case types.CLEAN_STATE:
      return defaultState.loading;

    default:
      return loadingState;
  }
};

const isFetchingUser = (state = defaultState.fetchingUser, action) => {
  switch (action.type) {
    case types.END_LOADING:
      return false;

    default:
      return state;
  }
};

const strongPassword = (
  strongPasswordState = defaultState.strongPassword,
  action
) => {
  switch (action.type) {
    case types.SET_STRONG_PASSWORD:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.strongPassword;

    default:
      return strongPasswordState;
  }
};

const user = (userState = defaultState.user, action) => {
  switch (action.type) {
    case types.SET_USER:
      return action.data;

    case types.SET_SELECTED_ENTITY_SECTOR: {
      const tmpUserState = { ...userState };
      const selectedEntitySector = tmpUserState.entitySectors.find(
        ({ id }) => id === action.entitySectorId
      );

      tmpUserState.selectedEntitySector = selectedEntitySector;

      return tmpUserState;
    }

    case types.SET_SELECTED_FISCAL_YEAR: {
      const tmpUserState = { ...userState };

      tmpUserState.RBM_fiscalYear = action.data;

      return tmpUserState;
    }

    case types.SET_CURRENT_EMPLOYMENT: {
      let tmpUserState = { ...userState };

      tmpUserState = {
        ...tmpUserState,
        currentEmployeePosition: action.data.currentEmployeePosition,
        leaveDays: action.data.leaveDays,
        currentDate: action.data.currentDate,
      };

      return tmpUserState;
    }

    case types.LOGOUT:
      return defaultState.user;

    default:
      return userState;
  }
};

const ippisModules = (state = defaultState.ippisModules, action) => {
  switch (action.type) {
    case types.SET_IPPIS_MODULES:
      return action.data;

    case types.LOGOUT:
      return defaultState.ippisModules;

    default:
      return state;
  }
};

const selectedModule = (state = defaultState.selectedModule, action) => {
  switch (action.type) {
    case types.SET_SELECTED_MODULE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.selectedModule;

    default:
      return state;
  }
};
const analytics = (state = defaultState.analytics, action) => {
  switch (action.type) {
    case types.SET_ANALYTICS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.analytics;

    default:
      return state;
  }
};
const onWait = (waitState = defaultState.onWait, action) => {
  switch (action.type) {
    case types.START_WAIT:
      return true;

    case types.END_WAIT:
      return false;

    case types.CLEAN_STATE:
      return defaultState.onWait;

    default:
      return waitState;
  }
};

const letterCustomization = (
  letterCustomizationState = defaultState.letterCustomization,
  action
) => {
  switch (action.type) {
    case types.SET_LETTER_CUSTOMIZATION: {
      return action.data;
    }
    case types.CLEAN_STATE:
      return defaultState.letterCustomization;

    default:
      return letterCustomizationState;
  }
};
export default {
  env,
  loading,
  isFetchingUser,
  strongPassword,
  user,
  ippisModules,
  selectedModule,
  onWait,
  analytics,
  letterCustomization
};
