import disciplineTypes from "./action-types";
import axios from "axios";
import { showError, showSuccess } from "../../app/toastify";

import commonTypes from "../common/action-types";
// import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...disciplineTypes };

export const getSanctionsWithFaultCategory = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/discipline/sanctions");

      dispatch({
        type: types.SET_SANCTIONS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getFaultWithSanction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/discipline/faults");

      dispatch({
        type: types.SET_FAULTS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getMyCases = (setShowLoader) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setShowLoader(true)
      const { data } = await axios.get("/api/discipline/my-cases");

      dispatch({
        type: types.SET_MY_CASES,
        data,
      });
      setShowLoader(false)
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setShowLoader(false)
      // if (isSessionExpired(error)) {
      //   dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      // }
      showError(error);
    }
  };
};

export const getCase = (caseId, setLoading, setShowCaseDetail) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setLoading(true);
      const { data } = await axios.get("/api/discipline/case/" + caseId);

      dispatch({
        type: types.SET_CASE,
        data,
      });

      setLoading(false);
      setShowCaseDetail(true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      setLoading(false);
      dispatch({ type: types.END_LOADING });
      // if (isSessionExpired(error)) {
      //   dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      // }
      showError(error);
    }
  };
};

export const getCaseAttachments = (caseId, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/discipline/case-attachments/" + caseId
      );

      dispatch({
        type: types.SET_CASE_ATTACHMENTS,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // if (isSessionExpired(error)) {
      //   dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      // }
      showError(error);
    }
  };
};

export const approveResponseToExplanationLetter = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/approve-response-to-explanation-letter",
        formData
      );

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });

      // dispatch({
      //   type: types.SET_CASE,
      //   data: {},
      // });
      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess("Case approved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      // if (isSessionExpired(error)) {
      //   dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      // }
      showError(error);
    }
  };
};
export const getCaseApproverLogs = (caseId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/api/discipline/case-approver-logs/${caseId}`
      );

      dispatch({
        type: types.SET_CASE_APPROVER_LOGS,
        data,
      });
    } catch (error) {
      // showError(error);
    }
  };
};

export const getCaseLetters = (caseId,setIsLoading) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_CASE_LETTERS,
        data:[],
      });
      setIsLoading(true)
      const { data } = await axios.get(
        `/api/discipline/case-letters/${caseId}`
      );

      dispatch({
        type: types.SET_CASE_LETTERS,
        data,
      });
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      // showError(error);
    }
  };
};

export const updateCasePendingLetter = (letterId, formData, callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      callback(false);
      const { data } = await axios.put(
        `/api/discipline/case-pending-letter/${letterId}`,
        formData
      );
      const caseLetters = data;
      dispatch({
        type: types.SET_CASE_LETTERS,
        data: caseLetters,
      });
      const pendingLetter = caseLetters.find(
        ({ letterTypeId, letterStatusId }) =>
          +letterTypeId === 5 && +letterStatusId === 1
      );
      dispatch({
        type: types.SET_CASE_PENDING_LETTER,
        data: pendingLetter,
      });
      callback(true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      callback(false);
      dispatch({ type: types.END_LOADING });
      // if (isSessionExpired(error)) {
      //   dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      // }
      showError(error);
    }
  };
};
export const initiateRespondLetter = (formData, currentCase, callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      callback(true, null);
      const { data } = await axios.post(
        "/api/discipline/initiate-respond-letter",
        formData
      );

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data.case,
      });
      currentCase.request = data.case;
      dispatch({
        type: types.SET_CASE,
        data: currentCase,
      });
      const caseLetters = data.caseLetters;
      dispatch({
        type: types.SET_CASE_LETTERS,
        data: caseLetters,
      });
      const pendingLetter = caseLetters.find(
        ({ letterTypeId, letterStatusId }) =>
          +letterTypeId === 5 && +letterStatusId === 1
      );
      callback(false, pendingLetter);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      callback(true, null);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
export const onViewLetter = (letterId, callback) => {
  return async (dispatch) => {
    try {
      
      callback(true, null);
      const { data } = await axios.get(
        "/api/discipline/view-letter/"+letterId);

      callback(false, data);

    } catch (error) {
      callback(true, null);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
export const signLetter = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/sign-letter",
        formData
      );

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });
      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess("Signed & approved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getCasePendingLetter = (caseId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/api/discipline/case-pending-letter/${caseId}`
      );

      dispatch({
        type: types.SET_CASE_PENDING_LETTER,
        data,
      });
    } catch (error) {
      // showError(error);
    }
  };
};
