import { Skeleton, Link } from "@mui/material";
import rwandaLogo from "../../assets/rwanda-logo.png";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLetterCustomization } from "../../../store/common/actions";

const LetterTemplateHeader = (props) => {
  const {
    loading,
    entitySectorId,
    letterCustomization,
    getLetterCustomization,
    disableToEdit = false,
    loadHeaderContent,
    planningEntityId

  } = props;
  const [showEditHeaderDialog, setShowEditHeaderDialog] = useState(false);
  const [letterPosition, setLetterPosition] = useState("CenteredPosition");
  useEffect(() => {
    if (letterCustomization)
      setLetterPosition(
        letterCustomization?.letterPosition || "CenteredPosition"
      );
  }, [letterCustomization]);

  useEffect(() => {
    if (loadHeaderContent) loadHeaderContent(letterCustomization);
  }, [loadHeaderContent, letterCustomization]);

  useEffect(() => {
    getLetterCustomization(entitySectorId, planningEntityId);
  }, []);

  return (
    <>
      {loading ? (
        <>
          <Skeleton variant="rectangular" width={"100%"} height={80} />
        </>
      ) : (
        <>
          {!!letterCustomization &&
          !!letterCustomization.isHeaderLogoSettled ? (
            <>
              <div
                className={` my-3 d-flex justify-content-${
                  letterCustomization?.letterPosition === "LeftPosition"
                    ? "left"
                    : "center"
                } `}
              >
                <div
                  className="px-2"
                  style={{
                    minWidth: letterCustomization?.imgSize?.minWidth + "px",
                    maxWidth: letterCustomization?.imgSize?.maxWidth + "px",
                    minHeight: letterCustomization?.imgSize?.minHeight + "px",
                    maxHeight: letterCustomization?.imgSize?.maxHeight + "px",
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "1px",
                    backgroundColor: "#ffff",
                  }}
                >
                  <img
                    src={
                      "data:image/png;base64," +
                      letterCustomization.letterHeaderLogo
                    }
                    style={{
                      width: "calc(100% - 5px)",
                      height: "calc(100% - 5px)",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>
              <div
            style={{
              backgroundColor: "#078ece",
              height: `${letterCustomization?.styles?.headingBorderSize}px`,
              border: `${letterCustomization?.styles?.headingBorderSize}px solid ${letterCustomization?.styles?.headingBorderColor}`,
              borderRadius: "2px",
            }}
          ></div>

              {!disableToEdit && (
                <div className="w-100 text-center">
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowEditHeaderDialog(true);
                    }}
                  >
                    {" "}
                    <span className="px-0 py-0">(Letter customization)</span>{" "}
                  </Link>{" "}
                </div>
              )}
            </>
          ) : (
            <>
              <div className="letterhead d-flex flex-column justify-content-center w-100 py-2">
                <div
                  className="letterhead-title w-100"
                  style={{ fontWeight: "bold" }}
                >
                  REPUBLIC OF RWANDA
                </div>
                <div className="w-100">
                  <img
                    style={{ width: "100px", height: "100px" }}
                    src={rwandaLogo}
                    alt="Logo"
                  />
                </div>
                <div className="letterhead-subtitle w-100">
                  <span
                    style={{ fontWeight: "bold", textTransform: "uppercase" }}
                  >
                    {(letterCustomization?.entity &&
                      letterCustomization?.entity?.name?.toUpperCase()) ||
                      ""}
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ loading, onWait, letterCustomization }) => {
  return {
    loading,
    onWait,
    letterCustomization,
  };
};
export default connect(mapStateToProps, {
  getLetterCustomization,
})(LetterTemplateHeader);
