import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getSalary } from "../../../store/payroll/actions";
import { Skeleton, Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import { getMyActivities } from "../../../store/rbm/actions";
import { getAnalytics } from "../../../store/common/actions";
import { currencyFormat, textCapitalized } from "../../common/components/Utils";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import InfoIcon from "@mui/icons-material/Info";
import PayHistoryDetailDialog from "../components/PayHistoryDetailDialog";
import DownloadPaySlipDialog from "../components/DownloadPaySlipDialog";
import HiddenText from "../../common/components/HiddenText";
const InprogressPayroll = (props) => {
  useEffect(() => {
    document.title = "Employee Portal - Payroll | In-Progress";
  });

  const { user, analytics, getAnalytics } = props;

  const [showLoader, setShowLoader] = useState(false);
  const [showPayHistoryDetail, setShowPayHistoryDetail] = useState(false);
  const [selectedPayHistory, setSelectedPayHistory] = useState(null);
  const [showDownloadPaySlip, setShowDownloadPaySlip] = useState(false);
  useEffect(() => {
    if (!analytics.payrolls.length) getAnalytics(setShowLoader);
  }, []);
  return (
    <>
      <div
        style={{
          overflow: "hidden",
        }}
      >
        {showPayHistoryDetail && (
          <PayHistoryDetailDialog
            showPayHistoryDetail={showPayHistoryDetail}
            setShowPayHistoryDetail={setShowPayHistoryDetail}
            selectedPayHistory={selectedPayHistory}
            setSelectedPayHistory={setSelectedPayHistory}
            selectedEmployee={user}
            setShowDownloadPaySlip={setShowDownloadPaySlip}
            disableDownload={true}
          />
        )}

        <div className="container mt-4">
          <div className="row">
            <div className="mx-auto col-lg-12">
              <div className="row">
                <div className="mx-auto col-lg-10">
                  <div className="row">
                    {!showLoader && !analytics?.payrolls?.length && (
                      <div className="col-lg-12 mb-3">
                        <div
                          className="card rounded"
                          style={{
                            border: "1px solid #078ece",
                          }}
                        >
                          <div className="card-header">
                            <div className="card-title flex-column">
                              <span className="text-gray-800 text-uppercase">
                                <AccountBalanceWalletIcon className="mr-1" />
                                NO PAYROLL INPROGRESS
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {!showLoader && !!analytics?.payrolls?.length && (
                      <>
                        {analytics?.payrolls.map((payroll, index) => (
                          <div className="col-lg-12 mb-3" key={index}>
                            <div
                              className="card rounded"
                              style={{
                                border: "1px solid #078ece",
                              }}
                            >
                              <div className="card-header">
                                <div className="card-title flex-column">
                                  <span className="text-gray-800">
                                    <AccountBalanceWalletIcon className="mr-1" />
                                    PAYROLL SUMMARY OF{" "}
                                    <strong className="text-primary">
                                      {" "}
                                      {payroll &&
                                        payroll?.dateHumanReadable}{" "}
                                    </strong>{" "}
                                    <span className="mr-2 badge badge-secondary">
                                      {payroll?.payrollGroupName}{" "}
                                    </span>
                                    <span className="mr-2 badge badge-secondary">
                                      {payroll?.payrollTypeName}{" "}
                                    </span>
                                  </span>
                                  <div className="float-right d-flex align-items-center">
                                    <span
                                      className={` mr-2 badge badge-${
                                        payroll?.statusId ===
                                          "RECEIVED_BY_BANK" ||
                                        payroll?.statusId === "SEND_TO_BANK" ||
                                        payroll?.statusId === "SIGNED_AS_PAID"
                                          ? "success"
                                          : "info"
                                      }`}
                                      style={{ width: "96px" }}
                                    >
                                      {payroll?.statusId === "SEND_TO_BANK" ||
                                      payroll?.statusId === "SIGNED_AS_PAID"
                                        ? "Paid"
                                        : "Inprogress..."}
                                    </span>
                                    <button
                                      className="btn btn-secondary btn-sm  ml-2"
                                      onClick={() => {
                                        setSelectedPayHistory(payroll);
                                        setShowPayHistoryDetail(true);
                                      }}
                                    >
                                      <i className="fas fa-eye fa-1x  mr-1"></i>
                                      View details
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body d-flex  flex-column">
                                <div className="d-flex  flex-wrap">
                                  <div className="border-dashed border-gray-300 text-success border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                                    <div className="fs-6 fw-bold text-gray-700">
                                      <span
                                        className="text-center"
                                        style={{ fontSize: "13px" }}
                                      >
                                        <HiddenText
                                          value={currencyFormat(
                                            payroll?.basePay || 0
                                          )}
                                        />
                                      </span>
                                    </div>
                                    <div className="fw-semibold text-gray-400 text-center">
                                      <span style={{ fontSize: "12px" }}>
                                        Base Salary
                                      </span>
                                    </div>
                                  </div>

                                  <div className="border-dashed border-gray-300 text-success border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                                    <div className="fs-6 fw-bold text-gray-700">
                                      <span
                                        className="text-center"
                                        style={{ fontSize: "13px" }}
                                      >
                                        <HiddenText
                                          value={currencyFormat(
                                            payroll?.grossSalary || 0
                                          )}
                                        />
                                      </span>
                                    </div>
                                    <div className="fw-semibold text-gray-400 text-center">
                                      <span style={{ fontSize: "12px" }}>
                                        Gross Salary
                                      </span>
                                    </div>
                                  </div>

                                  <div className="border-dashed border-gray-300 text-success border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                                    <div className="fs-6 fw-bold text-gray-700">
                                      <span
                                        className="text-center"
                                        style={{ fontSize: "13px" }}
                                      >
                                        <HiddenText
                                          value={currencyFormat(
                                            payroll?.netLumpsum || 0
                                          )}
                                        />
                                      </span>
                                    </div>
                                    <div className="fw-semibold text-gray-400 text-center">
                                      <span style={{ fontSize: "12px" }}>
                                        Net Lumpsum
                                      </span>
                                    </div>
                                  </div>

                                  <div className="border-dashed border-gray-300 text-success border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                                    <div className="fs-6 fw-bold text-gray-700">
                                      <span
                                        className="text-center"
                                        style={{ fontSize: "13px" }}
                                      >
                                        <HiddenText
                                          value={currencyFormat(
                                            payroll?.withHolds || 0
                                          )}
                                        />
                                      </span>
                                    </div>
                                    <div className="fw-semibold text-gray-400 text-center">
                                      <span style={{ fontSize: "12px" }}>
                                        Witheld
                                      </span>
                                    </div>
                                  </div>

                                  <div className="border-dashed border-gray-300 text-success border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                                    <div className="fs-6 fw-bold text-gray-700">
                                      <span
                                        className="text-center"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <HiddenText
                                          value={currencyFormat(
                                            payroll?.netSalary || 0
                                          )}
                                        />
                                      </span>
                                    </div>
                                    <div className="fw-semibold text-gray-400 text-center">
                                      <span style={{ fontSize: "12px" }}>
                                        Net Salary
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                    {showLoader && (
                      <div className="col-lg-12 mb-3">
                        <div
                          className="card rounded"
                          style={{
                            border: "1px solid #078ece",
                          }}
                        >
                          <div className="card-header">
                            <div className="card-title flex-column">
                              <span className="text-gray-800">
                                <AccountBalanceWalletIcon className="mr-1" />

                                <Skeleton />
                              </span>
                              <span className="float-right">
                                <Skeleton />
                              </span>
                            </div>
                          </div>
                          <div className="card-body d-flex  flex-column">
                            <div className="d-flex  flex-wrap">
                              <div className="border-dashed border-gray-300 text-success border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                                <div className="fs-6 fw-bold text-gray-700">
                                  <Skeleton
                                    sx={{ bgcolor: "grey.900" }}
                                    variant="rectangular"
                                    width={86}
                                    height="100%"
                                  />
                                </div>
                                <div className="fw-semibold text-gray-400">
                                  <Skeleton />
                                </div>
                              </div>

                              <div className="border-dashed border-gray-300 text-success border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                                <div className="fs-6 fw-bold text-gray-700">
                                  <Skeleton
                                    sx={{ bgcolor: "grey.900" }}
                                    variant="rectangular"
                                    width={86}
                                    height="100%"
                                  />
                                </div>
                                <div className="fw-semibold text-gray-400">
                                  <Skeleton />
                                </div>
                              </div>

                              <div className="border-dashed border-gray-300 text-success border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                                <div className="fs-6 fw-bold text-gray-700">
                                  <Skeleton
                                    sx={{ bgcolor: "grey.900" }}
                                    variant="rectangular"
                                    width={86}
                                    height="100%"
                                  />
                                </div>
                                <div className="fw-semibold text-gray-400">
                                  <Skeleton />
                                </div>
                              </div>

                              <div className="border-dashed border-gray-300 text-success border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                                <div className="fs-6 fw-bold text-gray-700">
                                  <Skeleton
                                    sx={{ bgcolor: "grey.900" }}
                                    variant="rectangular"
                                    width={86}
                                    height="100%"
                                  />
                                </div>
                                <div className="fw-semibold text-gray-400">
                                  <Skeleton />
                                </div>
                              </div>

                              <div className="border-dashed border-gray-300 text-success border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                                <div className="fs-6 fw-bold text-gray-700">
                                  <Skeleton
                                    sx={{ bgcolor: "grey.900" }}
                                    variant="rectangular"
                                    width={86}
                                    height="100%"
                                  />
                                </div>
                                <div className="fw-semibold text-gray-400">
                                  <Skeleton />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, loading, analytics }) => {
  return { user, loading, analytics };
};
export default connect(mapStateToProps, { getAnalytics })(InprogressPayroll);
