import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Typography, Box, Autocomplete, TextField } from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
import { getMyCases, getCase } from "../../../store/discipline/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";

import ReactPaginate from "react-paginate";
import moment from "moment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TableSkeleton from "../../common/components/TableSkeleton";
import BackdropLoader from "../../common/components/BackdropLoader";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ContentLayout from "../../common/components/ContentLayout";
import { requestStatusColor } from "../../common/components/Utils";
import ViewCaseDialog from "../components/ViewCaseDialog";
import NextPlanIcon from '@mui/icons-material/NextPlan';
const CaseCard = (props) => {
  const { request, getCase, setLoading1, loading1 } = props;
  const [showCaseDetail, setShowCaseDetail] = useState(false);
  return (
    <>
      <BackdropLoader isLoading={loading1} />
      <tr
        disabled={loading1}
        className="appeal-hovered elevated"
        onClick={() => {
          if (!loading1) getCase(request.id, setLoading1, setShowCaseDetail);
        }}
      >
        <td className="text-left">
          <strong> {request.id} </strong>
        </td>

        <td className="text-left text-truncate">{request.entityName}</td>

        {/* <td className="text-left text-truncate">{request.unitName}</td>
        <td className="text-left text-truncate">
          {request.positionName} ({request.levelName}.{request.scaleName}){" "}
        </td> */}

        <td className="text-left text-truncate">
          {request.decisionLevel === 2 ? "Second Level" : "First Level"}
        </td>
        <td className="text-left text-truncate">
          {request.caseSanctionName || "N/A"}
        </td>

        <td className="text-right">{moment(request.createdOn).format("ll")}</td>
        <td className="text-right">{moment(request.updatedOn).format("ll")}</td>

        <td className="text-right">
        <span
            className={`badge py-1 px-2 badge-${requestStatusColor(
              [
                {
                  className: "primary",
                  statusIds: [1],
                },
                {
                  className: "success",
                  statusIds: [2],
                },
              ],
              request.caseStatusId
            )}`}
          >
            {request.caseStatusName}
          </span>

          { request.caseStatusId ===1 && request?.currentLetterStatusId && request?.currentLetterDesc && (
            <>
              <span
                className="py-0 px-1 text-secondary"
                style={{ fontSize: "12px" }}
              >
                {" "}
                {request.currentLetterDesc}
              </span>{" "}
              <span className="py-0 px-0" style={{ fontSize: "12px" }}>
                -
              </span>
              <span
                style={{ fontSize: "12px" }}
                className={`py-0 px-1 text-${requestStatusColor(
                  [
                    {
                      className: `${
                        request.currentLetterStatusId === 1
                          ? "warning"
                          : "success"
                      }`,
                      statusIds: [+request.currentLetterStatusId],
                    },
                  ],
                  request.currentLetterStatusId
                )}`}
              >
                {request.currentLetterStatusName}
              </span>{" "}
              {!!request?.isEmployeeDelayedToRespond && (
                  <>
                    {" "}
                    <p>
                      <small className="text-danger">Your response is overdue.</small>
                    </p>{" "}
                  </>
                )}
            </>
          )}
        </td>

        <td className="text-right">
          {[1].includes(request.caseStatusId) && [1].includes(request.currentLetterTypeId) && [2].includes(request.currentLetterStatusId) ? (
            <>
              <button
                type="button"
                disabled={loading1}
                className="btn btn-primary m-2"
              >
                <TaskAltIcon /> Respond
              </button>
            </>
          ):
          [1].includes(request.caseStatusId) && [5].includes(request.currentLetterTypeId) && [1].includes(request.currentLetterStatusId) ? (
            <>
              <button
                type="button"
                disabled={loading1}
                className="btn btn-success m-2"
              >
                <NextPlanIcon /> Resume
              </button>
            </>
          ) 
          : (
            <>
              <button
                type="button"
                disabled={loading1}
                className="btn btn-primary m-2"
              >
                <OpenInNewIcon />
              </button>
            </>
          )}
        </td>
      </tr>
      {showCaseDetail && (
        <ViewCaseDialog
          showCaseDetail={showCaseDetail}
          setShowCaseDetail={setShowCaseDetail}
        />
      )}
    </>
  );
};

const MyDisciplineCases = (props) => {
  const { myCases, getMyCases, getCase } = props;
  useEffect(() => {
    document.title = "Employee Portal - Discipline | My Cases";
  });
  const [searchTeam, setSearchTeam] = useState("");

  const [loading1, setLoading1] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const onSearch = (term) => {
    setSearchTeam(term);
  };

  const [filteredMyCases, setFilteredMyAppeals] = useState([...myCases]);

  const [filterStatus, setFilterStatus] = useState(null);
  useEffect(() => {
    if(!myCases.length)  getMyCases(setShowLoader);
  }, []);

  useEffect(() => {
    const filtered = [...myCases].filter(
      ({ id, positionName, entityName, caseStatusName }) =>
        (entityName &&
          entityName.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (id && id.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (positionName &&
          positionName.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (entityName &&
          entityName.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (caseStatusName &&
          caseStatusName.toLowerCase().includes(searchTeam.toLowerCase()))
    );
    setFilteredMyAppeals(filtered);
  }, [searchTeam, myCases]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(filteredMyCases);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, myCases, filteredMyCases]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredMyCases.length || 0;

    setItemOffset(newOffset);
  };

  const filterAppealStatus = () => {
    let tempFilteredRequestStatus = [];

    myCases?.forEach((request) => {
      if (
        !tempFilteredRequestStatus.find(
          ({ name }) => name === request.caseStatusName
        )
      )
        tempFilteredRequestStatus.push({
          id: request.caseStatusId,
          name: request.caseStatusName,
        });
    });
    return tempFilteredRequestStatus;
  };

  return (
    <>
      <ContentLayout>
        <div className="row">
          <div className="col-lg-4">
            <Typography
              variant="h6"
              noWrap
              component="div"
              // sx={{ display: { xs: "none", sm: "block" } }}
            >
              <span>Discipline</span>
              <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                <span
                  className="material-icons  text-dark"
                  style={{ position: "relative", top: "7px" }}
                >
                  chevron_right
                </span>
              </span>
              <span style={{ color: "rgb(7, 142, 206)" }}>
                My Cases ({myCases.length})
              </span>
            </Typography>
          </div>

          <div className="col-lg-8 mt-2">
            <div className="row">
              <div className="col-lg-4 mt-1"></div>
              <div className="col-lg-4 mt-1">
                <Autocomplete
                  size="small"
                  id="filterStatus"
                  disabled={!myCases.length}
                  defaultValue={null}
                  value={filterStatus}
                  options={filterAppealStatus()}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, filterStatus) => {
                    setFilterStatus(filterStatus || null);
                    setSearchTeam("");
                    if (filterStatus) setSearchTeam(filterStatus.name);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, filterStatus) => (
                    <Box component="li" {...props} key={filterStatus.id}>
                      {filterStatus.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "35px!important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px!important",
                      }}
                      {...params}
                      label="Filter By Status"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "filterStatus", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-lg-4 mt-1">
                <SearchBox
                  disabled={!myCases?.length}
                  onSearch={onSearch}
                  placeholder="Search…"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="mt-3"
          style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}
        >
          <table className="table-striped table-hover table-sm  fixTableHead">
            {
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col" className="text-left">
                    No
                  </th>

                  <th scope="col" className="text-left">
                    Institution
                  </th>

                  <th scope="col" className="text-left">
                    Category
                  </th>
                  <th scope="col" className="text-left">
                    Sanction
                  </th>

                  <th scope="col" className="text-right">
                    RequestedAt
                  </th>
                  <th scope="col" className="text-right">
                    UpdatedDate
                  </th>

                  <th scope="col" className="text-right">
                    Status
                  </th>

                  <th scope="col" className="text-right">
                    <span className="mr-3">Actions</span>
                  </th>
                </tr>
              </thead>
            }

            {showLoader ? (
              <TableSkeleton cols={9} />
            ) : (
              <tbody>
                {paginatedItems.map((request, index) => (
                  <CaseCard
                    request={request}
                    index={index}
                    key={index}
                    setLoading1={setLoading1}
                    loading1={loading1}
                    getCase={getCase}
                  />
                ))}
              </tbody>
            )}
          </table>
        </div>
        <NoRecordMessage
          isEmpty={!filteredMyCases.length && !showLoader}
          title="No Discipline Cases found"
        />

        {!showLoader &&
          !!paginatedItems.length &&
          myCases.length >= itemsPerPage && (
            <div
              className="justify-content-center mt-2"
              style={{ overflow: "hidden" }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1  d-none d-md-inline">Previous</span>
                  </>
                }
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                activeLinkClassName="active"
              />
            </div>
          )}
      </ContentLayout>
    </>
  );
};

const mapStateToProps = ({ user, loading, myCases }) => {
  return { user, loading, myCases };
};
export default connect(mapStateToProps, {
  getMyCases,
  getCase,
})(MyDisciplineCases);
