import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  Autocomplete,
  Box,
  DialogActions,
  Typography,
  CardHeader,
  Card,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";

import { submitFirstLevelAppeal } from "../../../store/e-appeal/actions";

import { showError } from "../../../app/toastify";
import { textCapitalized } from "../../common/components/Utils";
import WidgetUploadComponent from "../../common/components/WidgetUploadComponent";
import ErrorMessage from "../../common/components/ErrorMessage";
import ActionDialog from "../../common/components/ActionDialog";
import { useHistory } from "react-router-dom";
import "react-quill/dist/quill.snow.css";

import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import SanctionLetter from "./SanctionLetter";

const NewAppealDialog = (props) => {
  const {
    user,
    loading,
    addNewAppealForm,
    setAddNewAppealForm,
    selectedAppealType,
    submitFirstLevelAppeal,
  } = props;

  const history = useHistory();

  const onClose = () => {
    setAddNewAppealForm(false);
  };
  const maxLength = 250;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [yesIAgree, setYesIAgree] = useState(false);
  const [yesIAgreeChecked, setYesIAgreeChecked] = useState(false);
  const [entitySector, setEntitySector] = useState(
    user.selectedEntitySector
      ? user.selectedEntitySector
      : user.entitySectors[0]
  );

  const [formData, setFormData] = useState({
    isDisciplineCase: selectedAppealType?.isDisciplineCase || false,
    decisionLevel: selectedAppealType?.decisionLevel || 0,
    caseId:
      selectedAppealType?.isDisciplineCase || false
        ? selectedAppealType.id
        : null,
    appealTypeId:
      selectedAppealType?.isDisciplineCase || false
        ? 18
        : selectedAppealType?.id || 18,
    entitySectorId:
      selectedAppealType?.isDisciplineCase || false
        ? selectedAppealType.entitySectorId
        : user.selectedEntitySector
        ? user.selectedEntitySector.id
        : user.entitySectors[0].id,
    message: "",
    subject:
      selectedAppealType?.isDisciplineCase || false
        ? (selectedAppealType?.caseSanctionName || "") +
          " Sanction - " +
          (selectedAppealType?.entityName || "")
        : selectedAppealType?.name === "Others"
        ? ""
        : selectedAppealType?.name || "",
    attachedFiles: [],
    // tagInstitutions: [],
  });

  const [errors, setErrors] = useState({
    fileHasError: false,
    messageHasError: false,
    subjectHasError: false,
    entitySectorHasError: false,
    hasError: false,
  });

  //console.log(entitySectors);
  const formValidator = () => {
    const error = {
      fileHasError: false,
      messageHasError: false,
      hasError: false,
      entitySectorHasError: false,
      subjectHasError: false,
    };

    if (!formData.message) {
      error.messageHasError = true;
      error.hasError = true;
    }

    if (!formData.subject) {
      error.subjectHasError = true;
      error.hasError = true;
    }

    if (!entitySector) {
      error.entitySectorHasError = true;
      error.hasError = true;
    }

    if (
      !selectedAppealType?.isDisciplineCase &&
      !formData.attachedFiles.length
    ) {
      error.fileHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };
  const onSave = () => {
    if (!errors.fileHasError) {
      if (!formValidator()) {
        formData.level = 1;
          const payload = new FormData();
          const keys = Object.keys(formData);
          for (const key of keys) {
            if (formData.hasOwnProperty(key)) {
              if (
                key === "attachedFiles" &&
                formData[key] &&
                !!formData[key].length
              )
                formData[key].forEach((row, i) => {
                  payload.append(key, row.file);
                });
              else payload.append(`${key}`, `${formData[key]}`);
            }
          }

          submitFirstLevelAppeal(
            payload,
            setFormData,
            setIsSubmitting,
            onClose,
            history
          );
        
      }
    }
  };
  const onUploadSuccess = (files) => {
    setErrors({ ...errors, fileHasError: false });
    setFormData({ ...formData, attachedFiles: files });
  };
  const onUploadErrors = (errors) => {
    if (errors) {
      setErrors({ ...errors, fileHasError: errors.fileHasError });
      showError(errors.message);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={addNewAppealForm}
        fullWidth
      >
        <DialogTitle
          className="text-primary"
          style={{
            height: "90px",
          }}
        >
          <div className="row">
            <div className="col-11">
              <h5
                style={{
                  background: "rgba(0,99,207,.08)",
                  padding: "10px 25px",
                  borderRadius: "5px",
                  margin: "0 25px 32px 0",
                  width: "-moz-fit-content",
                  width: "fit-content",
                  color: "#0063cf",
                }}
              >
                {selectedAppealType?.isDisciplineCase ? (
                  <>
                    {(selectedAppealType?.caseSanctionName || "") +
                      " Sanction - " +
                      (selectedAppealType?.entityName || "")}
                  </>
                ) : (
                  <> {selectedAppealType.name}</>
                )}
              </h5>
            </div>
            <div className="col-1">
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <span className="material-icons">close</span>
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {yesIAgree ? (
            <>
              <div className="row">
                <div className="col-12 mt-2">
                  <TextField
                    fullWidth
                    size="small"
                    // disabled={selectedAppealType.name !== "Others"}
                    rows={4}
                    name="amount"
                    autoFocus
                    label="Subject"
                    variant="outlined"
                    placeholder="Subject"
                    value={formData.subject}
                    inputProps={{
                      readOnly:
                        selectedAppealType?.isDisciplineCase ||
                        selectedAppealType.name !== "Others",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const subject = e.target.value;
                      setFormData({ ...formData, subject });
                      const subjectHasError = false;
                      setErrors({ ...errors, subjectHasError });
                    }}
                  />
                  <ErrorMessage
                    hasError={errors.subjectHasError}
                    message="Subject is required"
                  />
                </div>

                <div className="col-lg-12 mt-3">
                  {/* <ReactQuill
                placeholder="Please type your appeal message here...."
                theme="snow"
                value={formData.message}
                onChange={(message) => {
                  setFormData({
                    ...formData,
                    message: message,
                  });

                  setErrors({
                    ...errors,
                    messageHasError: false,
                  });
                }}
              /> */}
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    size="small"
                    name="message"
                    label={`Write your appeal message not great than ${maxLength} characters`}
                    variant="outlined"
                    placeholder={`Write your appeal message not great than ${maxLength} character`}
                    value={formData.message}
                    inputProps={{
                      maxLength: maxLength,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const message = e.target.value;
                      setFormData({
                        ...formData,
                        message,
                      });

                      setErrors({
                        ...errors,
                        messageHasError: false,
                      });
                    }}
                  />
                  {!!formData.message.length && (
                    <div className="float-right text-primary">
                      {formData.message.length}/{maxLength}
                    </div>
                  )}

                  <ErrorMessage
                    hasError={errors.messageHasError}
                    message="Appeal message is required"
                  />
                </div>

                <div className="col-lg-12 mt-3">
                  <Autocomplete
                    size="small"
                    id="entitySector"
                    readOnly={selectedAppealType?.isDisciplineCase || false}
                    defaultValue={null}
                    value={entitySector}
                    options={user.entitySectors}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, entitySector) => {
                      setEntitySector(entitySector || null);

                      const entitySectorId = entitySector ? entitySector.id : 0;
                      setFormData({
                        ...formData,
                        entitySectorId,
                      });

                      setErrors({
                        ...errors,
                        entitySectorHasError: false,
                      });
                    }}
                    getOptionLabel={(option) =>
                      textCapitalized(option.entityName, false) +
                      "(" +
                      textCapitalized(option.sectorName, false) +
                      ")"
                    }
                    renderOption={(props, entitySector) => (
                      <Box component="li" {...props} key={entitySector.id}>
                        {textCapitalized(entitySector.entityName)}-(
                        {textCapitalized(entitySector.sectorName)})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        style={{
                          height: "35px!important",
                          position: "relative",
                          borderRadius: "8px",
                          padding: "1px!important",
                        }}
                        {...params}
                        label="Choose institution to appeal"
                        inputProps={{
                          readOnly:
                            selectedAppealType?.isDisciplineCase || false,
                          ...params.inputProps,
                          autoComplete: "entitySector", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  <ErrorMessage
                    hasError={errors.entitySectorHasError}
                    message="[Institution to appeal] is required"
                  />
                </div>

                <div className="col-lg-12 mt-3">
                  {(selectedAppealType?.isDisciplineCase || false) && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          width: "100%",
                          // marginTop:'10px'
                        }}
                      >
                        <SanctionLetter
                        
                          caseSanctionName={
                            selectedAppealType?.caseSanctionName || ""
                          }
                          sanctionDocReferenceNo={
                            selectedAppealType?.currentLetterRefNo || selectedAppealType?.sanctionDocReferenceNo || ""
                          }
                        />
                      </div>
                    </>
                  )}
                  <WidgetUploadComponent
                    onSuccess={onUploadSuccess}
                    onErrors={onUploadErrors}
                    multiple={true}
                    acceptedFileSize="1074176"
                    acceptedFileTypes="pdf"
                    label="Upload supporting document *(pdf)"
                  />
                  <ErrorMessage
                    hasError={
                      errors.fileHasError && !formData.attachedFiles.length
                    }
                    message="Supporting document is required"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="card p-3">
                    {/* </div> */}
                    <div className="mt-0">
                      <span className="font-weight-bold">
                        Declaration of the appeal
                      </span>
                      <div className="mt-3">
                        <span>
                          I{" "}
                          <span className="text-primary">
                            {user.firstName + " " + user.lastName}
                          </span>
                          ,
                        </span>

                        <p className="mt-2">
                          Hereby declare that all the details will mentioned in
                          the appeal are in accordance with the truth and fact
                          as per my knowledge and I truly understand that any
                          abuse of the system shall affect my job.
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        yesIAgreeChecked
                          ? "border p-2 border-success"
                          : "border p-2 border-primary"
                      }
                    >
                      <div
                        className="d-flex flex-row align-items-center cursor-pointer max-w-content mr-1 "
                        style={{ fontSize: "16px" }}
                        onClick={() => {
                          setYesIAgreeChecked(!yesIAgreeChecked);
                          setTimeout(() => setYesIAgree(!yesIAgree), 500);
                        }}
                      >
                        <span className="font-weight-bold text-primary">
                          <span
                            className={
                              yesIAgreeChecked
                                ? "text-success mr-2"
                                : "text-primary mr-2"
                            }
                          >
                            Yes, I agree
                          </span>
                        </span>
                        {yesIAgreeChecked ? (
                          <CheckBoxRoundedIcon className="text-success" />
                        ) : (
                          <CheckBoxOutlineBlankRoundedIcon
                            style={{ cursor: "pointer" }}
                            className="text-primary"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {isSubmitting && (
            <ActionDialog showAction={isSubmitting} action="Submitting" />
          )}
        </DialogContent>
        {yesIAgree && (
          <DialogActions className="d-flex justify-content-center py-4">
            <button
              onClick={onSave}
              type="button"
              className="btn btn-primary  px-4"
              disabled={loading || isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user, entitySectors }) => {
  return {
    loading,
    user,
    entitySectors,
  };
};
export default connect(mapStateToProps, {
  submitFirstLevelAppeal,
})(NewAppealDialog);
