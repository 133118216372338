import React, { useState } from "react";
import moment from "moment";
import rejectedImg from "../../assets/rejected.jpg";
import cancelledImg from "../../assets/cancelled.jpg";
import LetterTemplateFooter from "./LetterTemplateFooter";
import LetterTemplateHeader from "./LetterTemplateHeader";
const CustomizedLetter = (props) => {
  const {
    enableToFillLetter = false,
    docLetterNo = "",
    signContent = "",
    subTitle = "",
    recipientContent,
    entitySectorId,
    letterBodyContentInput = "",
    letterBodyContent = "",
    title = "",
    copiedInstitutionContentInput = "",
    copiedInstitutionContent = "",
    ccSelectForm = "",
    loadFooterContent,
    rejected,
    cancelled,
    address = "",
    planningEntityId
  } = props;

  const [letterCustomization, setLetterCustomization] = useState(null);

  const loadHeaderContent = (data) => {
    // console.log(data);
    setLetterCustomization(data);
  };

  return (
    <>
      <div className="letterMainContent">
        <div className="letter2">
          <div
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",

              backgroundImage: !!cancelled
                ? `url('${cancelledImg}')`
                : !!rejected
                ? `url('${rejectedImg}')`
                : "none",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              opacity: 0.8, // Adjust transparency if needed
            }}
          >
            <LetterTemplateHeader
              entitySectorId={entitySectorId}
              planningEntityId={planningEntityId}
              loadHeaderContent={(data) => loadHeaderContent(data)}
              disableToEdit={true}
            />
            <div className="subletterhead">
              <table>
                <tr>
                  <td style={{ width: "70%" }}></td>
                  <td style={{ width: "30%" }}>
                    <p
                      className="date"
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        textAlign: "left",
                        fontFamily:
                          letterCustomization?.styles?.fontFamily ||
                          "Times New Roman",
                        lineHeight: letterCustomization?.styles?.lineHeight,

                        lineSpacing: `${
                          letterCustomization?.styles?.lineSpacing || 0
                        }px`,
                      }}
                    >
                      N<sup>o</sup>:
                      <span style={{ fontWeight: "bold" }}>{docLetterNo}</span>
                      <br />
                      {moment(new Date()).format("LL")}
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            {recipientContent && (
              <div className="recipient">
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize:
                      (letterCustomization?.styles?.fontSize || "12") + "px",
                    fontFamily:
                      letterCustomization?.styles?.fontFamily ||
                      "Times New Roman",
                    lineHeight: letterCustomization?.styles?.lineHeight,

                    lineSpacing: `${
                      letterCustomization?.styles?.lineSpacing || 0
                    }px`,
                  }}
                >
                  {recipientContent}
                </p>
              </div>
            )}

            <div className="content mb-3">
              {title && (
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize:
                      (letterCustomization?.styles?.fontSize || "12") + "px",
                    fontFamily:
                      letterCustomization?.styles?.fontFamily ||
                      "Times New Roman",
                    lineHeight: letterCustomization?.styles?.lineHeight,

                    lineSpacing: `${
                      letterCustomization?.styles?.lineSpacing || 0
                    }px`,
                  }}
                >
                  {title}
                </p>
              )}

              {subTitle && (
                <p
                  style={{
                    fontSize:
                      (letterCustomization?.styles?.fontSize || "12") + "px",
                    fontFamily:
                      letterCustomization?.styles?.fontFamily ||
                      "Times New Roman",
                    lineHeight: letterCustomization?.styles?.lineHeight,

                    lineSpacing: `${
                      letterCustomization?.styles?.lineSpacing || 0
                    }px`,
                  }}
                >
                  {subTitle}
                </p>
              )}

              <p>
                {enableToFillLetter ? (
                  letterBodyContentInput
                ) : (
                  <>
                    <div
                      style={{
                        fontSize:
                          letterCustomization?.styles?.fontSize + "px" ||
                          "12px",
                        fontFamily:
                          letterCustomization?.styles?.fontFamily ||
                          "Times New Roman",
                        lineHeight: letterCustomization?.styles?.lineHeight,
                        textAlign:
                          letterCustomization?.styles?.textAlign || "justify",
                        lineSpacing: `${
                          letterCustomization?.styles?.lineSpacing || 0
                        }px`,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: letterBodyContent,
                      }}
                    ></div>
                  </>
                )}{" "}
              </p>
            </div>
            {!enableToFillLetter && <>{signContent}</>}
            <div className="cc">
              {enableToFillLetter ? (
                copiedInstitutionContentInput
              ) : (
                <>
                {copiedInstitutionContent && <>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize:
                        (letterCustomization?.styles?.fontSize || "12") + "px",
                      fontFamily:
                        letterCustomization?.styles?.fontFamily ||
                        "Times New Roman",
                      lineHeight: letterCustomization?.styles?.lineHeight,

                      lineSpacing: `${
                        letterCustomization?.styles?.lineSpacing || 0
                      }px`,
                    }}
                  >
                    Cc:
                  </p>
                  <div
                    style={{
                      fontSize:
                        (letterCustomization?.styles?.fontSize || "12") + "px",
                      fontFamily:
                        letterCustomization?.styles?.fontFamily ||
                        "Times New Roman",
                      lineHeight: letterCustomization?.styles?.lineHeight,

                      lineSpacing: `${
                        letterCustomization?.styles?.lineSpacing || 0
                      }px`,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: copiedInstitutionContent,
                    }}
                  ></div>
                </>}
                
                </>
              )}{" "}
              {enableToFillLetter && (
                <>
                  <br /> {ccSelectForm}
                </>
              )}
            </div>
            <div className="mt-5 pt-5">
              <LetterTemplateFooter loadFooterContent={loadFooterContent} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomizedLetter;
