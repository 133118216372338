import React from "react";

const InstitutionDetails = ({ headOfInstitution }) => {
  return (
    <p style={{marginTop:'15px'}}>
      <span className="d-block text-danger">Not signed</span> <br />
      <p
        className="mt-4 py-0"
        style={{
          fontSize: "13px",
          fontWeight: 800,
          fontFamily: "Times New Roman",
        }}
      >
        {headOfInstitution?.firstName || ""} {headOfInstitution?.lastName || ""}
      </p>
      {headOfInstitution?.isMinster ? (
        <>
          <p
            className="mb-3"
            style={{
              fontSize: "15px",
              fontWeight: 800,
              position: "relative",
              fontFamily: "Times New Roman",
            }}
          >
            {headOfInstitution?.positionName || ""}
          </p>
        </>
      ) : (
        <>
          <p className="mt-4 py-0" style={{ fontSize: "15px" }}>
            {headOfInstitution?.positionName}
          </p>
          <p
            className="mb-3"
            style={{
              fontSize: "15px",
              fontWeight: 800,
              position: "relative",
              fontFamily: "Times New Roman",
            }}
          >
            {headOfInstitution?.institutionName || ""}
          </p>
        </>
      )}
    </p>
  );
};

export default InstitutionDetails;
