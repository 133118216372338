import { defaultState } from "./state";
import disciplineTypes from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...disciplineTypes };

// faults reducer
const faults = (faultsState = defaultState.faults, action) => {
  switch (action.type) {
    case types.SET_FAULTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.faults;

    default:
      return faultsState;
  }
};

//sanctions reducer
const sanctions = (sanctionsState = defaultState.sanctions, action) => {
  switch (action.type) {
    case types.SET_SANCTIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.sanctions;

    default:
      return sanctionsState;
  }
};

const myCases = (casesState = defaultState.myCases, action) => {
  switch (action.type) {
    case types.SET_MY_CASES:
      return action.data;
      
      case types.UPDATE_DISCIPLINARY_CASE: {
        const tmpState = [...casesState];
        const index = tmpState.findIndex(({ id }) => id === action.data.id);
        tmpState[index] = action.data;
        return tmpState;
      }

    case types.CLEAN_STATE:
      return defaultState.myCases;

    default:
      return casesState;
  }
};

const caseAttachments = (
  caseAttachmentsState = defaultState.caseAttachments,
  action
) => {
  switch (action.type) {
    case types.SET_CASE_ATTACHMENTS: {
      return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.caseAttachments;
    default:
      return caseAttachmentsState;
  }
};
const caseRequest = (caseRequestState = defaultState.caseRequest, action) => {
  switch (action.type) {
    case types.SET_CASE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.caseRequest;

    default:
      return caseRequestState;
  }
};
const caseApproverLogs = (
  caseApproverLogsState = defaultState.caseApproverLogs,
  action
) => {
  switch (action.type) {
    case types.SET_CASE_APPROVER_LOGS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.caseApproverLogs;

    default:
      return caseApproverLogsState;
  }
};

const caseLetters = (caseLettersState = defaultState.caseLetters, action) => {
  switch (action.type) {
    case types.SET_CASE_LETTERS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.caseLetters;

    default:
      return caseLettersState;
  }
};

const pendingLetter = (
  pendingLetterState = defaultState.pendingLetter,
  action
) => {
  switch (action.type) {
    case types.SET_CASE_PENDING_LETTER:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.pendingLetter;

    default:
      return pendingLetterState;
  }
};

export default {
  faults,
  sanctions,
  myCases,
  caseAttachments,
  caseRequest,
  caseApproverLogs,
  caseLetters,
  pendingLetter
};
