import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getUserJobResponsibility,
  addOrUpdateUserJobResponsibility,
} from "../../../store/profile/actions";
import AdvertSkeleton from "../../common/components/AdvertSkeleton";

export const JobResponsibility = (props) => {
  const {
    loading,
    getUserJobResponsibility,
    addOrUpdateUserJobResponsibility,
  } = props;

  const [userJobResponsibility, setUserJobResponsibility] = useState(null);
  const [jobResponsibilities, setJobResponsibilities] = useState("");

  useEffect(() => {
    getUserJobResponsibility(setUserJobResponsibility);
  }, []);

  useEffect(() => {
    setJobResponsibilities(userJobResponsibility?.jobResponsibilities || "");
  }, [userJobResponsibility]);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    userJobResponsibilityHasError: false,
  });

  const formValidator = () => {
    const error = {
      userJobResponsibilityHasError: false,
      hasError: false,
    };

    if (!jobResponsibilities) {
      error.userJobResponsibilityHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const handleSubmit = (e) => {
    if (!formValidator()) {
      addOrUpdateUserJobResponsibility(
        {
          id: userJobResponsibility?.id || "",
          jobResponsibilities: jobResponsibilities,
        },
        setIsLoading,
        setUserJobResponsibility
      );
    }
  };

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="publications">
        <CardContent>
          {loading && !isLoading ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                {/* title */}
                <div>
                  <Typography variant="h5" style={{ color: "#078ece" }}>
                  Current Job Responsibilities
                  </Typography>
                </div>
                {userJobResponsibility &&
                  +userJobResponsibility?.isApproved === 0 && (
                    <span className={`badge badge-warning`}>
                      Awaiting Supervisor Approval
                    </span>
                  )}
                {userJobResponsibility &&
                  +userJobResponsibility?.isApproved === 1 && (
                    <span className={`badge badge-success`}>
                      Supervisor Approved
                    </span>
                  )}
                {userJobResponsibility &&
                  +userJobResponsibility?.isApproved === 2 && (
                    <span className={`badge badge-danger`}>Supervisor Declined</span>
                  )}
              </div>
              <div className="px-md-0 mt-4">
                <form onSubmit={handleSubmit}>
                  <div className="form-group col-12">
                    <TextField
                      fullWidth
                      label="Please type your job responsibilities..."
                      placeholder="Please type your job responsibilities..."
                      id="jobResponsibilities"
                      value={jobResponsibilities || ""}
                      size="small"
                      multiline
                      minRows={10}
                      onChange={(e) => {
                        setJobResponsibilities(e.target.value);
                        setErrors({
                          ...errors,
                          userJobResponsibilityHasError: false,
                        });
                      }}
                      error={errors.userJobResponsibilityHasError}
                    />

                    {errors.userJobResponsibilityHasError && (
                      <small className="text-danger">
                        Job responsibilities is required
                      </small>
                    )}
                  </div>

                  <div className="form-group col-12 pb-3">
                    <Button
                      variant="contained"
                      type="button"
                      className={`px-5 float-right`}
                      disabled={
                        isLoading ||
                        (userJobResponsibility &&
                          +userJobResponsibility?.isApproved === 1)
                      }
                      onClick={handleSubmit}
                    >
                      {isLoading
                        ? "Wait..."
                        : !!userJobResponsibility
                        ? "Update"
                        : "Save"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = ({ user, env, loading, onWait }) => ({
  user,
  env,
  loading,
  onWait,
});

export default connect(mapStateToProps, {
  getUserJobResponsibility,
  addOrUpdateUserJobResponsibility,
})(JobResponsibility);
