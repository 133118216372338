import React, { useState, useEffect } from "react";
import {
  getPayHistories,
  downloadPayHistory,
} from "../../../store/payroll/actions";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";

import {
  IconButton,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  Autocomplete,
  TextField,
  Button,
  Badge,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";

import PayHistoryDetailDialog from "../components/PayHistoryDetailDialog";
import DownloadPaySlipDialog from "../components/DownloadPaySlipDialog";
import { currencyFormat, totalSum } from "../../common/components/Utils";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import { useTheme } from "@mui/material/styles";
import TableSkeleton from "../../common/components/TableSkeleton";
import HiddenText from "../../common/components/HiddenText";
const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const PayHistoryCard = (props) => {
  const {
    payHistory,
    index,
    setShowPayHistoryDetail,
    setSelectedPayHistory,
    setShowDownloadPaySlip,
    loading,
  } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <tr key={index}>
        <td>{payHistory.periodId}</td>
        <td
          style={{
            maxWidth: "128px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {payHistory.entityName}
        </td>
        <td
          style={{
            maxWidth: "128px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {payHistory.positionName}
        </td>
        <td>{payHistory.levelName}</td>
        <td>{payHistory.index}</td>
        <td>{payHistory.indexValue}</td>
        <td>{payHistory.payrollType}</td>
        <td className="text-right">{currencyFormat(payHistory.basePay)}</td>
        <td className="text-right">
          {currencyFormat(payHistory.housingAllowance)}
        </td>
        <td className="text-right">
          {currencyFormat(payHistory.transportAllowance)}
        </td>
        <td className="text-right">
          {currencyFormat(payHistory.taxableAllowance)}
        </td>
        <td className="text-right">
          {currencyFormat(payHistory.exemptedAllowance)}
        </td>
        <td className="text-right">{currencyFormat(payHistory.grossSalary)}</td>
        <td className="text-right">{currencyFormat(payHistory.lumpsum)}</td>
        <td className="text-right">{currencyFormat(payHistory.netLumpsum)}</td>
        <td className="text-right">{currencyFormat(payHistory.partialNet)}</td>
        <td className="text-right">{currencyFormat(payHistory.withHolds)}</td>
        <td
          style={{ backgroundColor: "#e9ecef", fontWeight: "bolder" }}
          className="text-right"
        >
          {currencyFormat(payHistory.netSalary)}
        </td>
        <td className="text-right">
          <IconButton
            color="primary"
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setSelectedPayHistory(payHistory);
                  setShowPayHistoryDetail(true);
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons mr-1">visibility</span>
                Preview
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setSelectedPayHistory(payHistory);
                  setShowDownloadPaySlip(true);
                }}
                className="text-dark font-weight-light"
              >
                <span className="material-icons mr-1">file_download</span>
                Pay slip
              </MenuItem>
            </span>
          </Menu>
          {/* <IconButton
            color="primary"
            size="small"
            className="p-0"
            onClick={() => {
              setSelectedPayHistory(payHistory);
              setShowPayHistoryDetail(true);
            }}
            aria-label="settings"
          >
            <span className="material-icons">more_vert</span>
          </IconButton> */}
        </td>
      </tr>
    </>
  );
};

const PayHistory = (props) => {
  useEffect(() => {
    document.title = "Employee Portal - Payroll | Pay History";
  });

  const {
    user,
    loading,
    payHistories,
    getPayHistories,
    downloadPayHistory,
    showEmployeePayHistory,
    setShowEmployeePayHistory,
    selectedEmployee,
  } = props;

  const [filteredPayHistories, setFilteredPayHistories] = useState([
    ...payHistories,
  ]);

  const [filterByYear, setFilterByYear] = useState(null);
  const [filterByMonth, setFilterByMonth] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showPayHistoryDetail, setShowPayHistoryDetail] = useState(false);

  const [showDownloadPaySlip, setShowDownloadPaySlip] = useState(false);
  const [showDownloadPayHistory, setShowDownloadPayHistory] = useState(false);

  const [selectedPayHistory, setSelectedPayHistory] = useState(null);

  const theme = useTheme();

  const getFilterByYears = () => {
    let tempFilterByYears = [];
    filteredPayHistories.forEach((payHistory, i) => {
      if (!tempFilterByYears.find(({ name }) => name === payHistory.year))
        tempFilterByYears.push({
          id: i,
          name: payHistory.year,
        });
    });

    return tempFilterByYears;
  };

  const getFilterByMonths = () => {
    let tempFilterByMonths = [];
    payHistories.forEach((payHistory, i) => {
      if (!tempFilterByMonths.find(({ name }) => name === payHistory.month))
        tempFilterByMonths.push({
          id: i,
          name: payHistory.month,
        });
    });

    return tempFilterByMonths;
  };

  useEffect(
    () => {
      if (!payHistories.length) getPayHistories(setShowLoader);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  useEffect(() => {
    const tempYear = filterByYear
      ? filterByYear.name.toString().toLowerCase()
      : null;
    const tempMonth = filterByMonth ? filterByMonth.name.toLowerCase() : null;
    const searching =
      !!tempYear && !!tempMonth
        ? "01." + tempMonth + "." + tempYear
        : !!tempMonth
        ? tempMonth
        : !!tempYear
        ? tempYear
        : "";

    const filtered = [...payHistories].filter(
      ({ payrollType, payrollId, year, month, dateHumanReadable }) =>
        payrollType.toLowerCase().includes(searching.toLowerCase()) ||
        payrollId.toLowerCase().includes(searching.toLowerCase()) ||
        year.toString().toLowerCase().includes(searching) ||
        month.toLowerCase().includes(searching) ||
        dateHumanReadable.toLowerCase().includes(searching)
    );

    setFilteredPayHistories(filtered);
    paginate(filtered);
  }, [filterByYear, filterByMonth, payHistories]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 12;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(filteredPayHistories);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, payHistories, filteredPayHistories]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredPayHistories.length || 0;

    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="row">
        <div className="d-flex  flex-wrap justify-content-end mt-3">
          <Autocomplete
            size="small"
            id="filterByYear"
            className="mr-1 mt-1"
            disabled={!payHistories.length}
            defaultValue={null}
            value={filterByYear}
            options={getFilterByYears()}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, filterByYear) => {
              setFilterByYear(filterByYear || null);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, filterByYear) => (
              <Box component="li" {...props} key={filterByYear.id}>
                {filterByYear.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                size="small"
                style={{
                  height: "35px!important",
                  width: "310px",
                  position: "relative",
                  borderRadius: "8px",
                  padding: "1px!important",
                }}
                {...params}
                label="Filter By Year"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "filterByYear", // disable autocomplete and autofill
                }}
              />
            )}
          />

          <Autocomplete
            size="small"
            id="filterByMonth"
            className="mr-1 mt-1"
            disabled={!payHistories.length}
            defaultValue={null}
            value={filterByMonth}
            options={getFilterByMonths()}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, filterByMonth) => {
              setFilterByMonth(filterByMonth || null);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, filterByMonth) => (
              <Box component="li" {...props} key={filterByMonth.id}>
                {filterByMonth.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                size="small"
                style={{
                  height: "35px!important",
                  position: "relative",
                  borderRadius: "8px",
                  padding: "1px!important",
                  width: "320px",
                }}
                {...params}
                label="Filter By Month"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "filterByMonth", // disable autocomplete and autofill
                }}
              />
            )}
          />

          <button
            disabled={!filteredPayHistories.length}
            onClick={() => {
              if (filterByYear && filterByMonth)
                downloadPayHistory(
                  user.id,
                  setShowDownloadPayHistory,
                  "?year=" + filterByYear.name + "&month=" + filterByMonth.name
                );
              else if (filterByYear)
                downloadPayHistory(
                  user.id,
                  setShowDownloadPayHistory,
                  "?year=" + filterByYear.name
                );
              else downloadPayHistory(user.id, setShowDownloadPayHistory);
            }}
            className="btn btn-secondary  float-right mt-1"
          >
            <i className="fas fa-download fa-1x  mr-1"></i> Download report
          </button>
        </div>

        <div className="col-12 mt-0">
          <div
            style={{
              width: "100%",
              maxHeight: "100%",
              overflow: "auto",
            }}
          >
            <table className="table-striped table-hover table-sm  fixTableHead mt-3">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <td
                    style={{
                      backgroundColor: "#e9ecef",
                      width: "50px",
                    }}
                  >
                    Period
                  </td>
                  <td style={{ backgroundColor: "#e9ecef" }}>Insitution</td>
                  <td style={{ backgroundColor: "#e9ecef" }}>Position</td>
                  <td style={{ backgroundColor: "#e9ecef" }}>Level</td>
                  <td style={{ backgroundColor: "#e9ecef" }}>Index</td>
                  <td style={{ backgroundColor: "#e9ecef" }}>I.V</td>
                  <td style={{ backgroundColor: "#e9ecef" }}>Payroll</td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#e9ecef" }}
                  >
                    Basic S.
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#e9ecef" }}
                  >
                    Housing
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#e9ecef" }}
                  >
                    Transport
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#e9ecef" }}
                  >
                    Taxable
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#e9ecef" }}
                  >
                    Exempted
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#e9ecef" }}
                  >
                    Gross S.
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#e9ecef" }}
                  >
                    Gross Lp.
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#e9ecef" }}
                  >
                    Net Lp.
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#e9ecef" }}
                  >
                    Partial N.
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#e9ecef" }}
                  >
                    Withheld
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#e9ecef" }}
                  >
                    Net Salary
                  </td>
                  <td style={{ backgroundColor: "#e9ecef" }}></td>
                </tr>
              </thead>

              {showLoader ? (
                <TableSkeleton cols={19} />
              ) : (
                <tbody style={{ fontSize: "9px" }}>
                  {paginatedItems.map((payHistory, index) => (
                    <PayHistoryCard
                      key={index}
                      index={index}
                      payHistory={payHistory}
                      setSelectedPayHistory={setSelectedPayHistory}
                      setShowPayHistoryDetail={setShowPayHistoryDetail}
                      setShowDownloadPaySlip={setShowDownloadPaySlip}
                      loading={loading}
                    />
                  ))}
                </tbody>
              )}
              <tfoot>
                <tr>
                  <th colSpan={7}>
                    <strong>Total:</strong>
                  </th>
                  <th className="text-right" style={{ fontSize: "9px" }}>
                    {currencyFormat(totalSum(filteredPayHistories, "basePay"))}
                  </th>
                  <th className="text-right" style={{ fontSize: "9px" }}>
                    {currencyFormat(
                      totalSum(filteredPayHistories, "housingAllowance")
                    )}
                  </th>
                  <th className="text-right" style={{ fontSize: "9px" }}>
                    {currencyFormat(
                      totalSum(filteredPayHistories, "transportAllowance")
                    )}
                  </th>

                  <th className="text-right" style={{ fontSize: "9px" }}>
                    {currencyFormat(
                      totalSum(filteredPayHistories, "taxableAllowance")
                    )}
                  </th>

                  <th className="text-right" style={{ fontSize: "9px" }}>
                    {currencyFormat(
                      totalSum(filteredPayHistories, "exemptedAllowance")
                    )}
                  </th>

                  <th className="text-right" style={{ fontSize: "9px" }}>
                    <HiddenText
                      value={currencyFormat(
                        totalSum(filteredPayHistories, "grossSalary")
                      )}
                    />
                  </th>
                  <th className="text-right" style={{ fontSize: "9px" }}>
                    {currencyFormat(totalSum(filteredPayHistories, "lumpsum"))}
                  </th>
                  <th className="text-right" style={{ fontSize: "9px" }}>
                    {currencyFormat(
                      totalSum(filteredPayHistories, "netLumpsum")
                    )}
                  </th>
                  <th className="text-right" style={{ fontSize: "9px" }}>
                    <HiddenText
                      value={currencyFormat(
                        totalSum(filteredPayHistories, "partialNet")
                      )}
                    />
                  </th>
                  <th className="text-right" style={{ fontSize: "9px" }}>
                    {currencyFormat(
                      totalSum(filteredPayHistories, "withHolds")
                    )}
                  </th>
                  <th
                    style={{
                      backgroundColor: "#e9ecef",
                      fontSize: "9px",
                      fontWeight: "bolder",
                    }}
                    className="text-right"
                  >
                    <HiddenText
                      value={currencyFormat(
                        totalSum(filteredPayHistories, "netSalary")
                      )}
                    />
                  </th>
                  <th></th>
                </tr>
              </tfoot>
            </table>

            <div
              className="justify-content-center mb-3"
              style={{ overflow: "hidden" }}
            >
              {!!paginatedItems.length &&
                payHistories.length >= itemsPerPage && (
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">Next</span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                    activeLinkClassName="active"
                  />
                )}
            </div>

            <NoRecordMessage
              isEmpty={!filteredPayHistories.length && !showLoader}
              title="No record found."
              message="You don't have any pay history."
            />
          </div>
        </div>
      </div>

      {showPayHistoryDetail && (
        <PayHistoryDetailDialog
          showPayHistoryDetail={showPayHistoryDetail}
          setShowPayHistoryDetail={setShowPayHistoryDetail}
          selectedPayHistory={selectedPayHistory}
          setSelectedPayHistory={setSelectedPayHistory}
          selectedEmployee={user}
          setShowDownloadPaySlip={setShowDownloadPaySlip}
        />
      )}
      {showDownloadPaySlip && (
        <DownloadPaySlipDialog
          selectedPayHistory={selectedPayHistory}
          setSelectedPayHistory={setSelectedPayHistory}
          showDownloadPaySlip={showDownloadPaySlip}
          setShowDownloadPaySlip={setShowDownloadPaySlip}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, payHistories }) => {
  return { user, loading, payHistories };
};
export default connect(mapStateToProps, {
  getPayHistories,
  downloadPayHistory,
})(PayHistory);
