export const defaultState = {
  faults: [],
  sanctions: [],
  cases: [],
  myCases: [],
  caseAttachments:[],
  caseRequest:{},
  caseApproverLogs:[],
  caseLetters:[],
  pendingLetter:null,
};
