const types = {
  SET_FAULTS: "SET_FAULTS",
  SET_SANCTIONS: "SET_SANCTIONS",
  SET_MY_CASES: "SET_MY_CASES",
  SET_CASE: "SET_CASE",
  SET_CASE_ATTACHMENTS: "SET_CASE_ATTACHMENTS",
  UPDATE_DISCIPLINARY_CASE: "UPDATE_DISCIPLINARY_CASE",
  SET_CASE_APPROVER_LOGS: "SET_CASE_APPROVER_LOGS",
  SET_CASE_LETTERS: "SET_CASE_LETTERS",
  SET_CASE_PENDING_LETTER: "SET_CASE_PENDING_LETTER",
};

export default types;
