import { showSuccess, showError } from "../../app/toastify";
import axios from "axios";

import TYPES from "./action-types";
import COM_TYPES from "../common/action-types";
const types = { ...COM_TYPES, ...TYPES };

export const getDistricts = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });

      const { data } = await axios.get(`/api/lookup/districts`);

      dispatch({ type: types.SET_DISTRICTS, data });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getLanguages = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/api/lookup/languages`);

      dispatch({ type: types.SET_LANGUAGES, data });
    } catch (error) {
      showError(error);
    }
  };
};

export const getPublicationTypes = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/api/lookup/publication-types`);

      dispatch({ type: types.SET_PUBLICATION_TYPES, data });
    } catch (error) {
      showError(error);
    }
  };
};

export const getLanguageLevels = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/lookup/language-levels`);

      dispatch({ type: types.SET_LANGUAGE_LEVELS, data });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getDisabilities = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/api/lookup/disabilities`);

      dispatch({ type: types.SET_DISABILITIES, data });
    } catch (error) {
      showError(error);
    }
  };
};

export const getDisabilityLevels = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/api/lookup/disability-levels`);

      dispatch({ type: types.SET_DISABILITY_LEVELS, data });
    } catch (error) {
      showError(error);
    }
  };
};

export const getCountries = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/lookup/countries`);

      dispatch({ type: types.SET_COUNTRIES, data });
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getDegrees = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/lookup/degrees`);

      dispatch({ type: types.SET_DEGREES, data });
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getEducationalInstitutions = (countryId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/lookup/country-educational-institution/${countryId}`
      );

      dispatch({ type: types.SET_EDUCATIONAL_INSTITUTIONS, data });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getQualifications = (degreeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/lookup/qualifications/${degreeId}`
      );

      dispatch({ type: types.SET_QUALIFICATIONS, data });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

// export const getCertificateFields = () => {
//   return async (dispatch) => {
//     try {
//       const { data } = await axios.get(`/api/lookup/certificate-fields`);

//       dispatch({ type: types.SET_CERTIFICATE_FIELDS, data });
//     } catch (error) {
//       showError(error);
//     }
//   };
// };

export const getCertificates = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/api/lookup/certificates`);

      dispatch({ type: types.SET_CERTIFICATES, data });
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getUserEducations = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/profile/educations`);

      dispatch({ type: types.SET_USER_EDUCATIONS, data });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      showError(error);
      dispatch({ type: types.END_LOADING });
    }
  };
};

export const addOrUpdateUserEducation = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/profile/educations`, formData, {
        ignoreEncryption: true,
      });

      showSuccess("Saved successfully");
      onClose();

      dispatch({ type: types.ADD_OR_UPDATE_USER_EDUCATION, data });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      showError(error);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    }
  };
};

export const deleteUserEducation = (userEducationId, closeDialod) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete(`/api/profile/educations/${userEducationId}`);

      dispatch({ type: types.DELETE_USER_EDUCATION, id: userEducationId });

      showSuccess("Deleted successfully");

      closeDialod();

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      showError(error);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    }
  };
};

export const getUserExperiences = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/profile/experiences`);

      dispatch({ type: types.SET_USER_EXPERIENCES, data });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const addOrUpdateUserExperience = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/profile/experiences`, formData, {
        ignoreEncryption: true,
      });

      onClose();

      dispatch({ type: types.ADD_OR_UPDATE_USER_EXPERIENCE, data });

      showSuccess("Saved successfully");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const deleteUserExperience = (userExperienceId, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete(`/api/profile/experiences/${userExperienceId}`);

      onClose();

      dispatch({ type: types.DELETE_USER_EXPERIENCE, id: userExperienceId });

      showSuccess("Deleted successfully");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getUserLanguages = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/profile/languages`);

      dispatch({ type: types.SET_USER_LANGUAGES, data });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const addOrUpdateUserLanguage = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/profile/languages`, formData);

      onClose();

      dispatch({ type: types.SET_USER_LANGUAGES, data });

      showSuccess("Saved successfully");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const deleteUserLanguage = (userLanguageId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete(`/api/profile/languages/${userLanguageId}`);

      dispatch({ type: types.DELETE_USER_LANGUAGE, id: userLanguageId });

      showSuccess("Deleted successfully");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getUserPublications = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/profile/publications`);

      dispatch({ type: types.SET_USER_PUBLICATIONS, data });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const addOrUpdateUserPublication = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/profile/publications`, formData);

      onClose();

      dispatch({ type: types.SET_USER_PUBLICATIONS, data });

      showSuccess("Saved successfully");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const deleteUserPublication = (userPublicationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete(`/api/profile/publications/${userPublicationId}`);

      dispatch({ type: types.DELETE_USER_PUBLICATION, id: userPublicationId });

      showSuccess("Deleted successfully");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getUserDisabilities = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/profile/disabilities`);

      dispatch({ type: types.SET_USER_DISABILITIES, data });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const addOrUpdateDisability = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/profile/disabilities`, formData, {
        ignoreEncryption: true,
      });

      onClose();

      dispatch({ type: types.SET_USER_DISABILITIES, data });

      showSuccess("Saved successfully");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      showError(error);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    }
  };
};

export const deleteUserDisability = (disabilityId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete(`/api/profile/disabilities/${disabilityId}`);

      dispatch({ type: types.DELETE_USER_DISABILITY, id: disabilityId });

      showSuccess("Deleted successfully");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      showError(error);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    }
  };
};

export const getUserCertificates = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/profile/certificates`);

      dispatch({ type: types.SET_USER_CERTIFICATES, data });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const addOrUpdateUserCertificate = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/profile/certificates`, formData, {
        ignoreEncryption: true,
      });

      onClose();

      dispatch({ type: types.ADD_OR_UPDATE_USER_CERTIFICATE, data });

      showSuccess("Saved successfully");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      showError(error);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    }
  };
};

export const deleteUserCertificate = (userCertificateId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete(`/api/profile/certificates/${userCertificateId}`);

      dispatch({ type: types.DELETE_USER_CERTIFICATE, id: userCertificateId });

      showSuccess("Deleted successfully");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      showError(error);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    }
  };
};

export const getUserReferees = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/profile/referees`);

      dispatch({ type: types.SET_USER_REFEREES, data });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const addOrUpdateUserReferee = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/profile/referees`, formData);

      onClose();

      dispatch({ type: types.SET_USER_REFEREES, data });

      showSuccess("Saved successfully");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const deleteUserReferee = (userRefereeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete(`/api/profile/referees/${userRefereeId}`);

      dispatch({ type: types.DELETE_USER_REFEREE, id: userRefereeId });

      showSuccess("Deleted successfully");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      showError(error);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    }
  };
};

export const getUserProfile = (setUserResidence) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/profile/user-residence`);

      dispatch({ type: types.END_LOADING });
      setUserResidence(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const updateUserResidence = (
  formData,
  setUserResidence,
  getUserProfileStatus
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(`/api/profile`, formData);

      dispatch({ type: types.END_LOADING });

      setUserResidence(data);
      getUserProfileStatus();

      showSuccess("Profile updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const updateUserFile = (
  document,
  actionType,
  docName,
  setOnViewDocument,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);

      const { data } = await axios.post("/api/profile/update-user-file", {
        oldDocReferenceId: document.oldDocReferenceId,
        id: document.id,
        actionType: actionType,
        docName: docName,
        userId: document.userId,
      });

      if (actionType === "CHANGE_EDUCATION_FILE")
        dispatch({
          type: types.ADD_OR_UPDATE_USER_EDUCATION,
          data: { ...document, docReferenceId: data },
        });

      if (actionType === "CHANGE_EXPERIENCE_FILE")
        dispatch({
          type: types.ADD_OR_UPDATE_USER_EXPERIENCE,
          data: { ...document, docReferenceId: data },
        });

      if (actionType === "CHANGE_CERTIFICATE_FILE")
        dispatch({
          type: types.ADD_OR_UPDATE_USER_CERTIFICATE,
          data: { ...document, docReferenceId: data },
        });

      setLoading(false);
      setOnViewDocument(true);
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };
};

export const getUserProfileStatus = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/api/profile/status`);

      dispatch({ type: types.SET_USER_PROFILE_STATUS, data });
    } catch (error) {
      showError(error);
    }
  };
};

export const updateBRDStatus = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });

      const { data } = await axios.get(`/api/profile/update-brd-status`);

      dispatch({ type: types.SET_USER_PROFILE_STATUS, data });
      dispatch({ type: types.END_WAIT });

      showSuccess("BRD Status updated");
    } catch (error) {
      dispatch({ type: types.END_WAIT });

      showError(error);
    }
  };
};

export const getUserCV = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/profile/cv");

      dispatch({ type: types.SET_USER_CV, data });
    } catch (error) {
      showError(error);
    }
  };
};

export const addOrUpdateUserCV = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/profile/cv`, formData, {
        ignoreEncryption: true,
      });

      onClose();

      dispatch({ type: types.SET_USER_CV, data });

      showSuccess("Uploaded successfully");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};
export const requestWorkCertificate = (
  formData,
  setOnViewDocument,
  experience,
  setLoadingDocument
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });
      setLoadingDocument(true);
      const { data } = await axios.post(
        `/api/profile/request-work-certificate`,
        formData,
        {
          ignoreEncryption: true,
        }
      );

      dispatch({
        type: types.ADD_OR_UPDATE_USER_EXPERIENCE,
        data: { ...experience, docReferenceId: data },
      });

      setLoadingDocument(false);
      showSuccess("Work certificate generated successfully");
      setOnViewDocument(false);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      setLoadingDocument(false);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getUserTrainingDetails = (setUserTrainingDetail) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/profile/training-details`);
      setUserTrainingDetail(data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getUserJobResponsibility = (setUserJobResponsibility) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/profile/job-responsibility`);
      setUserJobResponsibility(data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const addOrUpdateUserTrainingDetail = (
  formData,
  setIsLoading,
  setUserTrainingDetail
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        `/api/profile/training-details`,
        formData
      );

      setIsLoading(true);
      setUserTrainingDetail(data);

      showSuccess("Saved successfully");
      setIsLoading(false);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const addOrUpdateUserJobResponsibility = (
  formData,
  setIsLoading,
  setUserJobResponsibility
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        `/api/profile/job-responsibility`,
        formData
      );

      setIsLoading(true);
      setUserJobResponsibility(data);

      showSuccess("Saved successfully");
      setIsLoading(false);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};
