import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from "@mui/material";

import React, { useEffect, useState } from "react";

import TextEditorComponent from "../../common/components/TextEditorComponent";
import { connect } from "react-redux";
import { showError } from "../../toastify";
import { updateCasePendingLetter } from "../../../store/discipline/actions";
import CustomizedLetter from "../../common/components/CustomizedLetter";
const maxCharCount = 1500;

const ViewLetterDialog = (props) => {
  const {
    action,
    pendingLetter,
    employee,
    setShowLetter,
    showLetter,
    enableToFillLetter,
    loading,
    updateCasePendingLetter,
    rejected,
    cancelled,
    selectedLetter,
    onUpdateLetter,
    signContent,
    setEnableToFillLetter,
  } = props;

  const [letter, setLetter] = useState(selectedLetter || pendingLetter);

  const [localStorageKey, setLocalStorageKey] = useState({
    letter: `${letter?.caseId}-draftCaseContent`,
    cc: `${letter?.caseId}-copiedInstitution`,
  });
  const [formData, setFormData] = useState({
    id: letter?.id,
    caseId: letter?.caseId,
    letterBodyContent: letter?.letterBodyContent,
    ccInstitutionNames: letter?.ccInstitutionNames,
  });

  const [letterFooterContent, setLetterFooterContent] = useState(null);
  const [ccEmails, setCCEmails] = useState(
    letter && !!letter.ccEmails ? JSON.parse(letter?.ccEmails || []) : []
  );
  // console.log(localStorageKey);
  useEffect(() => {
    window.localStorage.setItem(
      localStorageKey.letter,
      letter?.letterBodyContent
    );
    window.localStorage.setItem(localStorageKey.cc, letter?.ccInstitutionNames);
  }, [letter]);

  useEffect(() => {
    if (onUpdateLetter) {
      formData.letterBodyContent = window.localStorage.getItem(
        localStorageKey.letter
      );
      formData.ccInstitutionNames = window.localStorage.getItem(
        localStorageKey.cc
      );
      formData.ccEmails = JSON.stringify(ccEmails);
      onUpdateLetter(formData);
    }
  }, [formData, ccEmails]);


  useEffect(() => {
    // Get the first element with contenteditable="false"
    const editableElement = document.querySelector('[contenteditable="false"]');

    // To get all contenteditable="false" elements
    const editableElements = document.querySelectorAll('[contenteditable="false"]');

    // Prevent interaction with the first contenteditable="false" element
    if (editableElement) {
      // Prevent typing, deleting, and Enter key press
      editableElement.addEventListener("keydown", (e) => {
        e.preventDefault(); // Block typing and pressing Enter, Backspace, Delete
      });
      editableElement.addEventListener("click", (e) => {
        e.preventDefault(); // Block click events
      });
      editableElement.style.pointerEvents = "none";  // Disable mouse clicks

      // Prevent focus on the contenteditable="false" element
      editableElement.addEventListener("focus", (e) => e.preventDefault());
    }

    // Loop through all contenteditable="false" elements
    editableElements.forEach(element => {
      // Prevent typing, deleting, and Enter key press for each contenteditable element
      element.addEventListener("keydown", (e) => {
        e.preventDefault(); // Block typing and pressing Enter, Backspace, Delete
      });
      element.addEventListener("click", (e) => {
        e.preventDefault(); // Block click events
      });
      element.style.pointerEvents = "none";  // Disable mouse clicks

      // Prevent focus on the contenteditable="false" element
      element.addEventListener("focus", (e) => e.preventDefault());
    });
  }, []); // Empty dependency array ensures this effect runs once after initial render
  
  const onClose = () => {
    setShowLetter(false);
  };

  const getEditorContent = (data) => {
    setFormData((prevForm) => ({
      ...prevForm,
      letterBodyContent: data.contents,
    }));
  };
  const getEditorContent3 = (data) => {
    // console.log(data);
    setFormData((prevForm) => ({
      ...prevForm,
      ccInstitutionNames: data.contents,
    }));
    // letter.ccInstitutionNames = data.contents;
  };
  const handleLoadFooterContent = (data) => {
    // console.log(data);
    setLetterFooterContent(data);
  };

  const handleUpdateLetter = (isGoingNextPage) => {
    if (!letterFooterContent?.entity?.acronym) {
      showError(`The institution acronym is missing!`);
      return false;
    }

    if (
      !!letterFooterContent &&
      !letterFooterContent?.entity?.institutionPhone
    ) {
      showError(`The institution phone number is required. Please update it`);
      return false;
    }

    if (
      letterFooterContent?.entity?.institutionPhone.includes("+25078XXXXXXX")
    ) {
      showError(
        `The institution phone number (${
          letterFooterContent?.entity?.institutionPhone || ""
        }) does not seem correct. Please update it.`
      );
      return false;
    }

    if (
      !!letterFooterContent &&
      !letterFooterContent?.entity?.institutionEmail
    ) {
      showError(`The institution email is required. Please update it`);
      return false;
    }

    if (letterFooterContent?.entity?.institutionEmail.includes("example")) {
      showError(
        `The institution email (${
          letterFooterContent?.entity?.institutionEmail || ""
        }) does not seem correct. Please update it.`
      );
      return false;
    }

    if (!!letterFooterContent && !letterFooterContent?.entity?.poBox) {
      showError(`The institution PO.Box is required. Please update it`);
      return false;
    }

    if (!!letterFooterContent && !letterFooterContent?.entity?.websiteUrl) {
      showError(`The institution website url is required. Please update it`);
      return false;
    }
    if (!!letterFooterContent && !letterFooterContent?.entity?.twitterPage) {
      showError(
        `The institution former(twitter) is required. Please update it`
      );
      return false;
    }
    formData.letterBodyContent = window.localStorage.getItem(
      localStorageKey.letter
    );
    formData.ccInstitutionNames = window.localStorage.getItem(
      localStorageKey.cc
    );
    formData.ccEmails = JSON.stringify(ccEmails);
    updateCasePendingLetter(letter?.id, formData, (status) => {
      if (!!isGoingNextPage && !!status) setEnableToFillLetter(false);
    });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showLetter}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span
              class={`badge bg-${
                letter?.letterStatusId === 1
                  ? "primary"
                  : letter?.letterStatusId === 2
                  ? "success"
                  : letter?.letterStatusId === 4
                  ? "warning"
                  : "danger"
              } text-white px-2`}
            >
              {letter?.letterStatusName}
            </span>{" "}
            - {letter?.letterNo || ""} <br />
            <span className="text-primary">{letter?.letterDesc || ""}</span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="mt-0 mx-0" dividers>
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            <div className="mx-2 w-full">
              <CustomizedLetter
                planningEntityId={employee?.planningEntityId || letter?.planningEntityId || null}
                enableToFillLetter={enableToFillLetter}
                rejected={rejected}
                cancelled={cancelled}
                ccEmails={ccEmails}
                docLetterNo={letter?.letterNo || ""}
                address={employee?.address}
                signContent={signContent}
                entitySectorId={employee?.entitySectorId || null}
                letterBodyContentInput={
                  <>
                    <TextEditorComponent
                      id="editor-exit-textarea"
                      options={{
                        autoSave: false,
                        localStorageKey: localStorageKey.letter,
                        maxCharCount: maxCharCount,
                        defaultValue: !!window.localStorage.getItem(
                          localStorageKey.letter
                        )
                          ? window.localStorage.getItem(localStorageKey.letter)
                          : formData?.letterBodyContent,

                        styles: {
                          fontFamily:
                            letterFooterContent?.styles?.fontFamily || "",
                          fontSize: letterFooterContent?.styles?.fontSize || "",
                          textAlign:
                            letterFooterContent?.styles?.textAlign || "",
                          lineHeight:
                            letterFooterContent?.styles?.lineHeight || "",
                        },
                      }}
                      onEditorChange={(data) => {
                        console.log(data);
                        getEditorContent(data);
                      }}
                    />
                  </>
                }
                letterBodyContent={formData?.letterBodyContent || ""}
                // title={letter?.letterTitle || ""}

                loadFooterContent={(data) => handleLoadFooterContent(data)}
              />
            </div>
          </Box>
        </DialogContent>

        {action && (
          <>
            <DialogActions className="d-flex justify-content-center py-2">
              {enableToFillLetter && (
                <>
                 
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className="float-right px-5 my-1"
                   
                    onClick={() => {
                      handleUpdateLetter(true);
                    }}
                  >
                    {loading ? "Wait.." : <>Save</>}
                  </Button>
                </>
              )}

              {action}
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, pendingLetter }) => {
  return {
    loading,
    pendingLetter,
  };
};
export default connect(mapStateToProps, { updateCasePendingLetter })(
  ViewLetterDialog
);
