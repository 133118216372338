import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Skeleton } from "@mui/material";
import { align } from "suneditor/src/plugins";

const LetterTemplateFooter = (props) => {
  const { loading, letterCustomization, loadFooterContent } =
    props;

  useEffect(() => {
    if (loadFooterContent) loadFooterContent(letterCustomization);
  }, [letterCustomization]);
  return (
    <>
      {loading ? (
        <>
          {" "}
          <p style={{ borderTop: "3px solid #078ece" }}>
            <Skeleton variant="rectangular" width={"100%"} height={40} />
          </p>
        </>
      ) : (
        <>
          <div
            style={{
              backgroundColor: "#078ece",
              height: `${letterCustomization?.styles?.footerBorderSize}px`,
              border: `${letterCustomization?.styles?.footerBorderSize}px solid ${letterCustomization?.styles?.footerBorderColor}`,
              borderRadius: "2px",
            }}
          ></div>

          <div
            className="mt-0 pb-1"
            style={{
              bottom: "1px",

              padding: "0px",
              fontSize: "12px",
              // textAlign: letterCustomization?.styles?.textAlign || "justify",
            }}
          >
            <div
            style={{ marginTop: "5px",
              display:"flex",
              justifyContent:"center",
             }}>
              {/* PO Box */}
              <strong
                style={{
                  paddingRight: "10px",
                  marginRight: "2px",
                  fontSize: "12px",
                  fontFamily:
                    letterCustomization?.styles?.fontFamily ||
                    "Times New Roman",
                }}
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 32 32"
                  style={{
                    width: "12px",
                    height: "12px",
                    marginLeft: "8px",
                    verticalAlign: "middle",
                    marginRight: "4px",
                    marginTop: "-4px",
                  }}
                  xmlSpace="preserve"
                  fill="#078ece"
                  stroke="#078ece"
                >
                  <path
                    d="M16,25H2v-8c0-3.9,3.1-7,7-7h0c3.9,0,7,3.1,7,7V25z"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  ></path>
                  <path
                    d="M23,10L23,10c3.9,0,7,3.1,7,7v8H16"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  ></path>
                  <line
                    x1="9"
                    y1="10"
                    x2="23"
                    y2="10"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  ></line>
                  <rect
                    x="13"
                    y="25"
                    width="6"
                    height="6"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  ></rect>
                  <polyline
                    points="22,18 22,4.9 22,1 30,1 30,5 24,5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  ></polyline>
                </svg>
                <a
                  href="#"
                  style={{
                    color: "#363636",
                    textDecoration: "none",
                    fontSize: "12px",
                    fontFamily:
                      letterCustomization?.styles?.fontFamily ||
                      "Times New Roman",
                  }}
                >
                  {letterCustomization?.entity?.poBox?.toUpperCase() || ""}
                </a>
              </strong>

              {/* Phone */}
              <strong
                style={{
                  paddingRight: "10px",
                  marginRight: "2px",
                  fontSize: "12px",
                  fontFamily:
                    letterCustomization?.styles?.fontFamily ||
                    "Times New Roman",
                }}
              >
                <svg
                  viewBox="0 0 24 24"
                  style={{
                    width: "12px",
                    height: "12px",
                    marginLeft: "8px",
                    verticalAlign: "middle",
                    marginRight: "4px",
                    marginTop: "-4px",
                  }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 12C19.7614 12 22 9.76142 22 7C22 4.23858 19.7614 2 17 2C14.2386 2 12 4.23858 12 7C12 7.79984 12.1878 8.55582 12.5217 9.22624C12.6105 9.4044 12.64 9.60803 12.5886 9.80031L12.2908 10.9133C12.1615 11.3965 12.6035 11.8385 13.0867 11.7092L14.1997 11.4114C14.392 11.36 14.5956 11.3895 14.7738 11.4783C15.4442 11.8122 16.2002 12 17 12Z"
                    fill="#078ece"
                  ></path>
                  <path
                    d="M8.03759 7.31617L8.6866 8.4791C9.2723 9.52858 9.03718 10.9053 8.11471 11.8278C8.11459 11.8279 6.99588 12.9468 9.02451 14.9755C11.0525 17.0035 12.1714 15.8861 12.1722 15.8853C13.0947 14.9628 14.4714 14.7277 15.5209 15.3134L16.6838 15.9624C18.2686 16.8468 18.4557 19.0692 17.0628 20.4622C16.2258 21.2992 15.2004 21.9505 14.0669 21.9934C12.1588 22.0658 8.91828 21.5829 5.6677 18.3323C2.41713 15.0817 1.93421 11.8412 2.00655 9.93309C2.04952 8.7996 2.7008 7.77423 3.53781 6.93723C4.93076 5.54428 7.15317 5.73144 8.03759 7.31617Z"
                    fill="#078ece"
                  ></path>
                </svg>
                <a
                  href={`tel:${
                    letterCustomization?.entity?.institutionPhone || ""
                  }`}
                  style={{
                    color: "#363636",
                    textDecoration: "none",
                    fontSize: "12px",
                    fontFamily:
                      letterCustomization?.styles?.fontFamily ||
                      "Times New Roman",
                  }}
                >
                  {letterCustomization?.entity?.institutionPhone || ""}
                </a>
              </strong>
              <strong
                style={{
                  paddingRight: "10px",
                  marginRight: "2px",
                  fontSize: "12px",
                  fontFamily:
                    letterCustomization?.styles?.fontFamily ||
                    "Times New Roman",
                }}
              >
                <svg
                  viewBox="0 0 24 24"
                  style={{
                    width: "12px",
                    height: "12px",
                    marginLeft: "8px",
                    verticalAlign: "middle",
                    marginRight: "4px",
                    marginTop: "-4px",
                  }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#078ece"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M15.7285 3.88396C17.1629 2.44407 19.2609 2.41383 20.4224 3.57981C21.586 4.74798 21.5547 6.85922 20.1194 8.30009L17.6956 10.7333C17.4033 11.0268 17.4042 11.5017 17.6976 11.794C17.9911 12.0863 18.466 12.0854 18.7583 11.7919L21.1821 9.35869C23.0934 7.43998 23.3334 4.37665 21.4851 2.5212C19.6346 0.663551 16.5781 0.905664 14.6658 2.82536L9.81817 7.69182C7.90688 9.61053 7.66692 12.6739 9.51519 14.5293C9.80751 14.8228 10.2824 14.8237 10.5758 14.5314C10.8693 14.2391 10.8702 13.7642 10.5779 13.4707C9.41425 12.3026 9.44559 10.1913 10.8809 8.75042L15.7285 3.88396Z"
                      fill="#078ece"
                    ></path>{" "}
                    <path
                      d="M14.4851 9.47074C14.1928 9.17728 13.7179 9.17636 13.4244 9.46868C13.131 9.76101 13.1301 10.2359 13.4224 10.5293C14.586 11.6975 14.5547 13.8087 13.1194 15.2496L8.27178 20.1161C6.83745 21.556 4.73937 21.5863 3.57791 20.4203C2.41424 19.2521 2.44559 17.1408 3.88089 15.6999L6.30473 13.2667C6.59706 12.9732 6.59614 12.4984 6.30268 12.206C6.00922 11.9137 5.53434 11.9146 5.24202 12.2081L2.81818 14.6413C0.906876 16.5601 0.666916 19.6234 2.51519 21.4789C4.36567 23.3365 7.42221 23.0944 9.33449 21.1747L14.1821 16.3082C16.0934 14.3895 16.3334 11.3262 14.4851 9.47074Z"
                      fill="#078ece"
                    ></path>{" "}
                  </g>
                </svg>

                <a
                  href={letterCustomization?.entity?.websiteUrl || ""}
                  style={{
                    color: "#363636",
                    textDecoration: "none",
                    fontSize: "12px",
                    fontFamily:
                      letterCustomization?.styles?.fontFamily ||
                      "Times New Roman",
                  }}
                >
                  {letterCustomization?.entity?.websiteUrl || ""}
                </a>
              </strong>
              {/* Website */}
              <strong
                style={{
                  paddingRight: "10px",
                  marginRight: "2px",
                  fontSize: "12px",
                  fontFamily:
                    letterCustomization?.styles?.fontFamily ||
                    "Times New Roman",
                }}
              >
                <svg
                  viewBox="0 0 24 24"
                  style={{
                    width: "12px",
                    height: "12px",
                    marginLeft: "8px",
                    verticalAlign: "middle",
                    marginRight: "2px",
                  }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#078ece"
                >
                  <path
                    d="M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM16 12V13.5C16 14.8807 17.1193 16 18.5 16V16C19.8807 16 21 14.8807 21 13.5V12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21H16"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <a
                  href={`mailto:${
                    letterCustomization?.entity?.institutionEmail || ""
                  }`}
                  style={{
                    color: "#363636",
                    textDecoration: "none",
                    fontSize: "12px",
                    fontFamily:
                      letterCustomization?.styles?.fontFamily ||
                      "Times New Roman",
                  }}
                >
                  {letterCustomization?.entity?.institutionEmail || ""}
                </a>
              </strong>

              {/* Twitter */}
              <strong
                style={{
                  paddingRight: "10px",
                  marginRight: "2px",
                  fontSize: "12px",
                  fontFamily:
                    letterCustomization?.styles?.fontFamily ||
                    "Times New Roman",
                }}
              >
                <svg
                  viewBox="0 0 24 24"
                  style={{
                    width: "12px",
                    height: "12px",
                    marginLeft: "8px",
                    verticalAlign: "middle",
                    marginRight: "4px",
                    marginTop: "-4px",
                  }}
                  fill="#078ece"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23 3a10.9 10.9 0 01-3.14 1.53A4.48 4.48 0 0016.29 3a4.48 4.48 0 00-4.39 5.52A12.94 12.94 0 013 4.14a4.48 4.48 0 001.39 5.96A4.47 4.47 0 012 9.71v.05a4.48 4.48 0 003.6 4.39 4.52 4.52 0 01-2 .08 4.48 4.48 0 004.18 3.12A9 9 0 010 19.54 12.8 12.8 0 007 21c8.1 0 12.54-6.7 12.54-12.54 0-.19 0-.39-.02-.58A9 9 0 0023 3z"
                    fill="#078ece"
                  />
                </svg>
                <a
                  href={`https://x.com/${
                    letterCustomization?.entity?.twitterPage || ""
                  }`}
                  style={{
                    color: "#363636",
                    textDecoration: "none",
                    fontSize: "12px",
                    fontFamily:
                      letterCustomization?.styles?.fontFamily ||
                      "Times New Roman",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {letterCustomization?.entity?.twitterPage || ""}
                </a>
              </strong>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ loading, letterCustomization }) => {
  return {
    loading,
    letterCustomization,
  };
};

export default connect(mapStateToProps, {})(LetterTemplateFooter);
